import { faPalette, faTabletAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import QuestionnaireDesignPreview from './QuestionnaireDesignPreviewModule/QuestionnaireDesignPreview';
import AccessControl from '../AccessControl';
import Alert from '../Basic/AlertComponent';
import { GET_SIGNED_URL } from 'src/Query/questionnaires.query';
import { useLazyQuery } from '@apollo/client';
import axios from 'axios';
import { RegionFactory } from 'src/Utils/regions';
import { alphaNumbericAlphabet, getInitialColorStyles } from 'src/Utils/util';
import { customAlphabet } from 'nanoid';
import ContentLoader from 'react-content-loader';
import FileUpload from '../Common/FileUpload';
import ColorComponent from './ColorComponent';
import { ImageType, Regions, ToastTypes } from 'src/Utils/types';
import { GET_LICENSES_BY_IDS } from 'src/Query/licenses.query';
import { GET_PAGINATED_STYLE_TEMPLATES } from 'src/Query/styleTemplates.query';
import { connect } from 'react-redux';
import { setStyleTemplatesToStore } from 'src/States/Actions/styleTemplateAction';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { useToast } from '../Basic/Toastify';

const nanoid = customAlphabet(alphaNumbericAlphabet, 15);

const RadioSkeleton = () => {
  return (
    <ContentLoader viewBox='0 0 300 20'>
      <rect x='5' y='0' rx='5' ry='5' width='15' height='15' />
      <rect x='35' y='0' rx='4' ry='4' width='120' height='15' />
    </ContentLoader>
  );
};

const ColorSkeleton = () => {
  return (
    <ContentLoader viewBox='0 0 300 40'>
      <rect x='0' y='0' rx='5' ry='5' width='150' height='15' />
      <rect x='255' y='0' rx='4' ry='4' width='50' height='20' />
    </ContentLoader>
  );
};

const ImageSkeleton = () => {
  return (
    <ContentLoader viewBox='0 0 300 100'>
      <rect x='0' y='20' rx='5' ry='5' width='150' height='100' />
    </ContentLoader>
  );
};

const DesignTemplateSkeleton = () => {
  return (
    <ContentLoader viewBox='0 0 300 100'>
      <rect x='0' y='0' rx='0' ry='0' width='200' height='180' />
    </ContentLoader>
  );
};

const DropdownSkeleton = () => {
  return (
    <ContentLoader viewBox='0 0 300 20'>
      <rect x='300' y='0' rx='0' ry='0' width='300' height='180' />
    </ContentLoader>
  );
};

const animatedComponents = makeAnimated();

const QuestionnaireDesign = ({
  data,
  onQuestionFormEdit,
  styleTemplates,
  setStyleTemplatesToStore,
}: any) => {
  const { showToast } = useToast();

  const regions = useMemo(() => new RegionFactory(), []);
  const { questionnaire, editSelectedQuestionnaire } = data;

  const [option, setOption] = useState(
    data.questionnaire?.styles?.styleTemplateType || 'custom'
  );
  const [styles, setStyles] = useState<any[]>(
    getInitialColorStyles(questionnaire.styles)
  );

  useEffect(() => {
    setStyles(getInitialColorStyles(questionnaire.styles));
  }, [questionnaire]);

  const [paginationDetails, setPaginationDetails] = useState<any>({
    limit: 10,
    page: 1,
    licenseId: questionnaire.licenseId,
  });
  const [license, setLicense] = useState<any>(data?.license || {});
  const [companyLogo, setCompanyLogo] = useState<File | null>();
  const [backgroundImage, setBackgroundImage] = useState<File | null>();

  const [isImageLoading, setIsImageLoading] = useState(true);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [logoDeleted, setLogoDeleted] = useState(false);
  const [backgroundImgDeleted, setBackgroundImageDeleted] = useState(false);
  const [selectedStyleTemplate, setSelectedStyleTemplate] = useState<any>(null);
  const [region, setRegion] = useState<any>(
    regions.getRegion(Regions.EU_WEST_1)
  );

  const {
    handleSubmit,
    reset,
    formState,
    control,
    register,
    setValue,
    getValues,
  } = useForm<any>();

  const [loadStyleTemplates] = useLazyQuery(GET_PAGINATED_STYLE_TEMPLATES, {
    errorPolicy: 'all',
    onCompleted: (completedData) => {
      if (completedData) {
        setStyleTemplatesToStore(completedData.styleTemplates);
      }
    },
  });

  useEffect(() => {
    const params = paginationDetails;
    if (data.isProductModule) {
      params.productId = questionnaire.productId;
    }
    loadStyleTemplates({ variables: { ...params } });
  }, [
    paginationDetails,
    loadStyleTemplates,
    data.isProductModule,
    questionnaire,
  ]);

  const isStylesUpdated = (currentStyles: any, questionnaireStyles: any) => {
    let isUpdated = false;
    Object.keys(currentStyles).forEach((key: string) => {
      if (currentStyles[key] !== questionnaireStyles[key]) {
        isUpdated = true;
      }
    });
    return isUpdated;
  };

  const isImagesUpdated = useCallback(() => {
    return (
      logoDeleted || backgroundImgDeleted || companyLogo || backgroundImage
    );
  }, [logoDeleted, backgroundImgDeleted, companyLogo, backgroundImage]);

  const isDesignUpdated = useCallback(
    (isStylesUpdated: boolean, isImageUpdated: any) => {
      return (
        formState.isDirty ||
        isStylesUpdated ||
        isImageUpdated ||
        (questionnaire.styles?.styleTemplateType === 'template' &&
          selectedStyleTemplate?._id !== questionnaire.styles?.styleTemplateId)
      );
    },
    [formState, questionnaire, selectedStyleTemplate]
  );

  useEffect(() => {
    const colors: any = {};
    styles.forEach((color: any) => {
      colors[color.key] = color.value;
    });
    const isUpdated = isStylesUpdated(colors, questionnaire.styles);
    const isImageUpdated = isImagesUpdated();
    const isQuestionFormEdited = isDesignUpdated(isUpdated, isImageUpdated);
    onQuestionFormEdit(isQuestionFormEdited);
  }, [
    isDesignUpdated,
    isImagesUpdated,
    onQuestionFormEdit,
    questionnaire,
    styles,
  ]);

  const [loadLicense] = useLazyQuery(GET_LICENSES_BY_IDS, {
    errorPolicy: 'all',
    onCompleted: (completedData: any) => {
      setLicense(completedData?.licensesByIds[0]);
      setIsImageLoading(false);
      setIsDataLoading(false);
    },
    onError: (error: any) => {
      console.error(error);
    },
  });

  const getStyleTemplatesList = (styleTemplates: any[] = []) => {
    return styleTemplates.map((styleTemplate: any) => {
      return {
        label: styleTemplate.name,
        value: styleTemplate._id,
      };
    });
  };

  useEffect(() => {
    loadLicense({
      variables: {
        ids: [questionnaire.licenseId],
      },
    });
  }, [questionnaire, loadLicense]);

  useEffect(() => {
    const regions = new RegionFactory();
    if (license) setRegion(regions.getRegion(license.dataRegion));
  }, [license]);

  useEffect(() => {
    let styleTemplate: any = selectedStyleTemplate;
    if (!selectedStyleTemplate) {
      styleTemplate = styleTemplates?.styleTemplates?.find(
        (styleTemplate: any) =>
          styleTemplate?._id === data?.questionnaire?.styles?.styleTemplateId
      );
      setSelectedStyleTemplate(styleTemplate);
    }
    reset({
      designType: option || data?.questionnaire?.styles?.styleTemplateType,
      styleTemplate: {
        label: styleTemplate?.name,
        value: styleTemplate?._id,
      },
      logo: data?.questionnaire?.styles?.logo,
      backgroundImage: data?.questionnaire?.styles?.backgroundImage,
    });
  }, [data, reset, selectedStyleTemplate, styleTemplates, option]);

  const onColorSelect = (id: number, color: string) => {
    const selectedColorIndex = styles.findIndex(
      (color: any) => color.id === id
    );

    if (selectedColorIndex !== null && selectedColorIndex !== undefined) {
      const updatedStyles = [...styles];
      updatedStyles[selectedColorIndex].value = color;
      setStyles(updatedStyles);
    }
  };

  const [getSignedURL] = useLazyQuery(GET_SIGNED_URL, {
    errorPolicy: 'ignore',
  });

  const uploadImage = (url: string, file: File) => {
    return axios.put(url, file, {
      headers: {
        'Content-Type': file?.type,
        'Cache-Control': 'max-age=31536000',
      },
    });
  };

  const fetchSignURL = (licenseId: string, key: string) => {
    return getSignedURL({
      variables: {
        licenseId,
        key,
      },
    });
  };

  const getQuestionnaireUpdateParams = (
    params: any,
    logo: any,
    backgroundImageKey: any,
    questionnaire: any,
    selectedStyleTemplate?: any
  ) => {
    if (params?.designType === 'template') {
      return {
        ...params,
        name: selectedStyleTemplate?.name,
        styleTemplateType: params?.designType,
        backgroundColor: selectedStyleTemplate.backgroundColor,
        backgroundImage: selectedStyleTemplate.backgroundImage,
        buttonArrowColor: selectedStyleTemplate.buttonArrowColor,
        buttonBorderColor: selectedStyleTemplate.buttonBorderColor,
        buttonColor: selectedStyleTemplate.buttonColor,
        customCSS: selectedStyleTemplate.customCSS,
        logo: selectedStyleTemplate.logo,
        subTextColor: selectedStyleTemplate.subTextColor,
        titleColor: selectedStyleTemplate.titleColor,
        styleTemplateId: selectedStyleTemplate._id,
      };
    }

    const questionnaireUpdateParams = {
      ...params,
      styleTemplateType: params?.designType,
    };
    if (companyLogo) {
      questionnaireUpdateParams.logo = logo;
    }
    if (backgroundImage) {
      questionnaireUpdateParams.backgroundImage = backgroundImageKey;
    }

    questionnaireUpdateParams.logo =
      logoDeleted && (questionnaire?.styles?.logo === logo || !logo)
        ? null
        : questionnaireUpdateParams.logo;
    questionnaireUpdateParams.backgroundImage =
      backgroundImgDeleted &&
      (questionnaire?.styles?.backgroundImage === backgroundImageKey ||
        !backgroundImageKey)
        ? null
        : questionnaireUpdateParams.backgroundImage;

    return questionnaireUpdateParams;
  };

  const handleImageUpload = async (
    licenseId: string,
    key: string,
    file: File
  ) => {
    const {
      data: {
        signedURL: { url },
      },
    } = await fetchSignURL(licenseId, key);
    if (url) {
      return uploadImage(url, file);
    } else {
      setIsImageLoading(false);
      showToast('Error occurred while uploading image', ToastTypes.ERROR);
      return;
    }
  };

  const onImageDelete = (type: string) => {
    if (type === ImageType.LOGO) {
      setLogoDeleted(true);
    } else if (type === ImageType.BACKGROUND) {
      setBackgroundImageDeleted(true);
    }
  };

  const getColors = (styles: any[]) => {
    const colors: any = {};
    styles.forEach((color: any) => {
      colors[color.key] = color.value;
    });
    return colors;
  };

  const onSubmit = async (formData: any) => {
    if (
      formData?.designType === 'template' &&
      !formData?.styleTemplate?.value
    ) {
      showToast('Select a template before submission', ToastTypes.ERROR);
      return;
    }

    const colors: any = getColors(styles);

    let logoKey = null;
    if (companyLogo?.name) {
      logoKey = `${questionnaire.licenseId}/${
        questionnaire._id
      }/${nanoid()}.${companyLogo?.name.split('.').pop()}`;
    }
    let backgroundImageKey = null;
    if (backgroundImage?.name) {
      backgroundImageKey = `${questionnaire.licenseId}/${
        questionnaire._id
      }/${nanoid()}.${backgroundImage?.name.split('.').pop()}`;
    }
    setIsImageLoading(true);
    setIsDataLoading(true);

    const params = getQuestionnaireUpdateParams(
      { questionnaireId: questionnaire._id, ...colors, ...formData },
      logoKey,
      backgroundImageKey,
      questionnaire,
      selectedStyleTemplate
    );

    await editSelectedQuestionnaire({
      variables: {
        ...params,
      },
    });

    setIsDataLoading(false);
    setLogoDeleted(false);
    setBackgroundImageDeleted(false);
    setCompanyLogo(null);
    setBackgroundImage(null);

    if (formData.designType === 'custom') {
      let logoUploadPromise: any = null;
      let backgroundImagePromise: any = null;
      if (companyLogo && logoKey) {
        logoUploadPromise = handleImageUpload(
          questionnaire.licenseId,
          logoKey,
          companyLogo
        );
      }
      if (backgroundImage && backgroundImageKey) {
        backgroundImagePromise = handleImageUpload(
          questionnaire.licenseId,
          backgroundImageKey,
          backgroundImage
        );
      }
      await Promise.all([logoUploadPromise, backgroundImagePromise]);
    }

    setIsImageLoading(false);
  };

  const loadStyleTemplateOptions = (paginationDetails: any) => {
    setPaginationDetails(paginationDetails);
  };

  const handleStyleTemplateDropdownScroll = () => {
    setPaginationDetails({
      ...paginationDetails,
      limit: paginationDetails.limit + 10,
    });
  };

  const handleQuestionnaireDesignChange = (event: any) => {
    setValue('designType', event.target.value);
    setOption(event.target.value);
  };

  return (
    <div className='questions-design-screen'>
      <div className='question-design-content'>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='questionnaire-design'>
            <div className='design-content-actions'>
              <div className='design-content-action-info'>
                <div className='design-content-info-header'>Styling</div>

                {isDataLoading ? (
                  <div className='template-options'>
                    <RadioSkeleton />
                    <RadioSkeleton />
                  </div>
                ) : (
                  <div className='template-options'>
                    <div>
                      <label>
                        <input
                          {...register('designType')}
                          type='radio'
                          value='template'
                          className='link-type-form-field'
                          id='template-type'
                          onChange={handleQuestionnaireDesignChange}
                        />
                        <span className='link-type-txt'>
                          <FontAwesomeIcon
                            icon={faTabletAlt}
                            className='icon'
                          />
                          Use template
                        </span>
                      </label>
                    </div>
                    <div>
                      <label>
                        <input
                          {...register('designType')}
                          type='radio'
                          value='custom'
                          className='link-type-form-field'
                          id='custom-type'
                          onChange={handleQuestionnaireDesignChange}
                        />
                        <span className='link-type-txt'>
                          <FontAwesomeIcon icon={faPalette} className='icon' />
                          Custom styling
                        </span>
                      </label>
                    </div>
                  </div>
                )}
              </div>
              {option === 'template' && (
                <div className='design-content-action-info--title'>
                  <div className='design-content-info-header'>
                    Select template
                  </div>
                  <div className='templates-container'>
                    <div className='select-container'>
                      {isDataLoading ? (
                        <DropdownSkeleton />
                      ) : (
                        <Controller
                          control={control}
                          name='styleTemplate'
                          rules={{ required: false }}
                          render={({ field: { onChange, value } }) => {
                            return (
                              <Select
                                options={getStyleTemplatesList(
                                  styleTemplates?.styleTemplates
                                )}
                                value={value}
                                onInputChange={() =>
                                  loadStyleTemplateOptions(paginationDetails)
                                }
                                onMenuScrollToBottom={
                                  handleStyleTemplateDropdownScroll
                                }
                                onChange={(event: any) => {
                                  const styleTemplate =
                                    styleTemplates?.styleTemplates?.find(
                                      (styleTemplate: any) =>
                                        styleTemplate._id === event.value
                                    );
                                  setSelectedStyleTemplate(styleTemplate);
                                  return onChange(event);
                                }}
                                components={animatedComponents}
                              />
                            );
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              )}
              {option === 'custom' && (
                <AccessControl
                  allowedPermissions={['design:with_custom_styles']}
                  renderNoAccess={() => (
                    <Alert
                      message={
                        'Unauthorized. You do not have permission to custom design a questionnaire'
                      }
                    />
                  )}
                >
                  <div className='design-content-action-info'>
                    <div className='design-content-action-info--title'>
                      <div className='design-content-info-header'>Colors</div>
                    </div>
                    <div className='questionnaire-clr-options-container'>
                      {!isDataLoading ? (
                        styles &&
                        styles.map((color: any) => (
                          <ColorComponent
                            key={color.id}
                            color={color}
                            onColorSelect={onColorSelect}
                          />
                        ))
                      ) : (
                        <Fragment>
                          <ColorSkeleton />
                          <ColorSkeleton />
                          <ColorSkeleton />
                          <ColorSkeleton />
                          <ColorSkeleton />
                        </Fragment>
                      )}
                      <div className='questionnaire-clr-options-lbl'>
                        <div>Company logo</div>
                        {isImageLoading ? (
                          <ImageSkeleton />
                        ) : (
                          <FileUpload
                            url={region?.imageUrl}
                            imageKey={questionnaire?.styles?.logo}
                            onChange={setCompanyLogo}
                            altText={'Logo'}
                            type={ImageType.LOGO}
                            onImageDelete={onImageDelete}
                          />
                        )}
                      </div>
                      <div className='questionnaire-clr-options-lbl'>
                        <div>Background image</div>
                        {isImageLoading ? (
                          <ImageSkeleton />
                        ) : (
                          <FileUpload
                            url={region?.imageUrl}
                            imageKey={questionnaire?.styles?.backgroundImage}
                            onChange={setBackgroundImage}
                            altText={'Background'}
                            type={ImageType.BACKGROUND}
                            onImageDelete={onImageDelete}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </AccessControl>
              )}
            </div>

            <div className='design-content-info'>
              <div className='design-content-info-header'>Preview</div>
              {isDataLoading ? (
                <DesignTemplateSkeleton />
              ) : (
                <QuestionnaireDesignPreview
                  data={{
                    styleTemplate:
                      option === 'template'
                        ? selectedStyleTemplate
                        : { ...getColors(styles), ...getValues() },
                    region,
                    logoDeleted,
                    backgroundImgDeleted,
                    backgroundImage,
                    companyLogo,
                    isTemplate: true,
                    option,
                  }}
                />
              )}
              <div className='save-btn-wrapper'>
                <AccessControl
                  allowedPermissions={[
                    'design:with_custom_styles',
                    'design:with_template',
                  ]}
                >
                  <button
                    type='submit'
                    disabled={isImageLoading && isDataLoading}
                  >
                    Save
                  </button>
                </AccessControl>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    styleTemplates: state.styleTemplates,
  };
};

export default connect(mapStateToProps, { setStyleTemplatesToStore })(
  QuestionnaireDesign
);
