// TODO: try to add country flags to language options
// import ReactCountryFlag from 'react-country-flag';
import languageData from './meta/languages.json';

export const getLanguageOptions = (languages: any) => {
  return languages.map((language: any) => {
    return getOneLanguageOption(language);
  });
};

export const getOneLanguageOption = (language: any) => {
  return {
    value: language.code,
    label: language.name,
    // label: (
    //   <>
    //     <ReactCountryFlag countryCode={language.countryCode} />
    //     <span> {language.name}</span>
    //   </>
    // ),
  };
};

export const getOneLanguageObject = (code: any) => {
  return languageData.find((language: any) => {
    return language.code === code;
  });
};

export const getOneLanguageObjectArray = (languageOptions: any) => {
  return languageOptions.map((option: any) => {
    return getOneLanguageObject(option.value);
  });
};
