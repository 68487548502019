import React, { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowRight,
  faCode,
  faCog,
  faLayerGroup,
  faPassport,
  faQuestion,
  faShare,
  faFlagCheckered,
} from '@fortawesome/free-solid-svg-icons';

import QuestionsList from './QuestionsList';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  ARCHIVE_QUESTIONNAIRE,
  EDIT_QUESTIONNAIRE,
  GET_PAGINATED_QUESTIONNAIRES,
  QUESTIONNAIRE,
  UPDATE_QUESTIONNAIRE_STYLES,
} from '../../Query/questionnaires.query';
import { NavLink, useHistory, useRouteMatch } from 'react-router-dom';
import {
  setQuestionnaireDataToStore,
  setQuestionnaireToStore,
} from '../../States/Actions/questionnaireAction';
import { connect } from 'react-redux';
import { setQuestionDataToStore } from '../../States/Actions/questionAction';
import {
  ADD_QUESTION,
  DELETE_QUESTION,
  QUESTIONS,
  REORDER_QUESTIONS,
  UPDATE_QUESTION,
} from '../../Query/questions.query';
import { questionTypes } from '../../Utils/data';
import QuestionnaireShare from './QuestionnaireShare';
import QuestionnaireDesign from './QuestionnaireDesign';
import QuestionnaireSettings from './QuestionnaireSettings';
import AccessControl from '../AccessControl';
import { QuestionnaireStatus, Routes, ToastTypes } from 'src/Utils/types';
import ContentSaveWarningModal from './ContentSaveWarningModal';
import Feature from '../Feature';
import SurveyVersionsList from './SurveyVerionsList';
import QuestionnaireTranslations from './QuestionnaireTranslations';
import SharingMethods from './SharingMethods';
import { useToast } from '../Basic/Toastify';

const QuestionsTabs = (props: any) => {
  const { path } = useRouteMatch();

  const { showToast } = useToast();

  const { params }: { params: { questionnaireId: string } } = useRouteMatch();
  const [selectedQuestion, setSelectedQuestion] = useState<any>(null);

  const [isQuestionAdded, setIsQuestionAdded] = useState(false);
  const [isQuestionDeleted, setIsQuestionDeleted] = useState(false);
  const [isQuestionsReordered, setIsQuestionsReordered] = useState(false);
  const [loadQuestions, setLoadQuestions] = useState(false);
  const [selectedTab, setSelectedTab] = useState(1);
  const [isQuestionFormEdited, setIsQuestionFormEdited] = useState(false);

  const [skipFetchQuestions, setSkipFetchQuestions] = useState(true);

  const [openArchiveModal, setOpenArchiveModal] = useState(false);
  const [openUnsavedContentModal, setOpenUnsavedContentModal] = useState(false);
  const [isProductModule, setIsProductModule] = useState(false);
  const [isTranslationsVisible, setIsTranslationVisible] = useState(false);

  useEffect(() => {
    setIsProductModule(path.includes('/products/questionnaires'));
  }, [path]);

  const history = useHistory();

  let timeoutIntervalId: any;

  useEffect(() => {
    if (params?.questionnaireId) {
      setSkipFetchQuestions(false);
    }
  }, [params]);

  useEffect(() => {
    setIsTranslationVisible(
      props.questionnaire?.supportedLanguages?.length > 1
    );
  }, [props.questionnaire]);

  const [paginationDetails, setPaginationDetails] = useState<any>({
    limit: 20,
    next: null,
    hasNext: false,
    previous: null,
    hasPrevious: false,
    total: 0,
  });

  const toggleUnsavedContentModal = () => {
    setOpenUnsavedContentModal(!openUnsavedContentModal);
  };

  const displayToastMessage = () => {
    if (isQuestionAdded) {
      showToast('Question created successfully');
      setIsQuestionAdded(false);
    } else if (isQuestionDeleted) {
      showToast('Question deleted successfully');
      setIsQuestionDeleted(false);
    } else if (isQuestionsReordered) {
      showToast('Questions reordered successfully');
      setIsQuestionsReordered(false);
    }
  };

  const [getQuestionnaires] = useLazyQuery(GET_PAGINATED_QUESTIONNAIRES, {
    errorPolicy: 'all',
    onCompleted: (completedData: any) => {
      if (completedData) {
        props.setQuestionnaireDataToStore(completedData.questionnaires);
        props.setQuestionDataToStore();
      }
    },
  });

  const [getQuestionnaire] = useLazyQuery(QUESTIONNAIRE, {
    variables: {
      questionnaireId: params.questionnaireId,
    },
    errorPolicy: 'all',
    onCompleted: (completedData: any) => {
      props.setQuestionnaireToStore(completedData.questionnaire);
    },
  });

  const { refetch: refetchQuestions } = useQuery(QUESTIONS, {
    variables: {
      questionnaireId: params.questionnaireId,
      ...paginationDetails,
    },
    skip: skipFetchQuestions,
    errorPolicy: 'all',
    onCompleted: (completedData: any) => {
      getQuestionnaire({
        variables: {
          questionnaireId: params.questionnaireId,
        },
      });
      setLoadQuestions(true);
      props.setQuestionDataToStore(completedData.questions);
      displayToastMessage();
    },
  });

  useEffect(() => {
    getQuestionnaire({
      variables: {
        questionnaireId: params.questionnaireId,
      },
    });
    refetchQuestions();
  }, [getQuestionnaire, refetchQuestions, params.questionnaireId]);

  const [editQuestionnaire] = useMutation(EDIT_QUESTIONNAIRE, {
    errorPolicy: 'none',
    onCompleted: () => {
      showToast('Questionnaire updated successfully');
    },
    onError: (error: any) => {
      showToast(error.message, ToastTypes.ERROR);
      console.error(error);
    },
  });

  const [editSelectedQuestionnaire, { loading: editQuestionnaireLoading }] =
    useMutation(UPDATE_QUESTIONNAIRE_STYLES, {
      errorPolicy: 'none',
      onCompleted: () => {
        getQuestionnaire({
          variables: {
            questionnaireId: params.questionnaireId,
          },
        });
        showToast('Questionnaire updated successfully');
      },
      onError: (error: any) => {
        showToast(error.message, ToastTypes.ERROR);
        console.error(error);
      },
    });

  const [addQuestion, { loading: addQuestionnaireLoading }] = useMutation(
    ADD_QUESTION,
    {
      errorPolicy: 'none',
      onCompleted: (completedData: any) => {
        refetchQuestions();
        setIsQuestionAdded(true);
        setSelectedQuestion(completedData?.addQuestion);
      },
      onError: (error: any) => {
        showToast(error.message, ToastTypes.ERROR);
      },
    }
  );

  const [deleteQuestion] = useMutation(DELETE_QUESTION, {
    errorPolicy: 'none',
    onCompleted: () => {
      refetchQuestions();
      setIsQuestionDeleted(true);
      setSelectedQuestion(null);
    },
    onError: (error: any) => {
      showToast(error.message, ToastTypes.ERROR);
    },
  });

  const toggleArchiveModal = () => {
    setOpenArchiveModal(!openArchiveModal);
  };

  const [
    archiveSelectedQuestionnaire,
    { error: archiveQuestionnaireError, loading: archiveQuestionnaireLoading },
  ] = useMutation(ARCHIVE_QUESTIONNAIRE, {
    errorPolicy: 'none',
    onCompleted: () => {
      getQuestionnaires({
        variables: {
          limit: 10,
          page: 1,
          licenseId: props.license?._id,
          searchTerm: props.searchData.searchTerm,
          isArchived: false,
          status: QuestionnaireStatus.CONCEPT.toUpperCase(),
        },
      });
      toggleArchiveModal();
      showToast('Questionnaire archived successfully');
      history.push(Routes.QUESTIONNAIRES_VIEW);
    },
    onError: (error: any) => {
      showToast(error.message, ToastTypes.ERROR);
      console.error(error);
    },
  });

  const handleDeleteQuestion = (data: any) => {
    setSelectedQuestion(null);
    setLoadQuestions(false);
    deleteQuestion(data);
  };

  const handleReOrderQuestions = (result: any) => {
    setSelectedQuestion(null);
    setLoadQuestions(false);
    reOrderQuestions(result);
  };

  const [reOrderQuestions] = useMutation(REORDER_QUESTIONS, {
    errorPolicy: 'none',
    onCompleted: () => {
      refetchQuestions();
      setIsQuestionsReordered(true);
      setSelectedQuestion(null);
    },
    onError: (error: any) => {
      showToast(error.message, ToastTypes.ERROR);
      console.error(error);
    },
  });

  const [updateQuestion] = useMutation(UPDATE_QUESTION, {
    errorPolicy: 'none',
    onCompleted: (completedData: any) => {
      refetchQuestions();
      showToast('Question updated successfully');
      setSelectedQuestion(null);
      setSelectedQuestion(completedData?.updateQuestion);
    },
    onError: (error: any) => {
      showToast(error.message, ToastTypes.ERROR);
      console.error(error);
    },
  });

  const handleUpdateQuestion = (data: any) => {
    setSelectedQuestion(null);
    setLoadQuestions(false);
    console.log(data);
    updateQuestion(data);
  };

  const handleAddQuestion = ({ question }: any) => {
    setLoadQuestions(false);
    setSelectedQuestion(null);
    const pageId = uuid();
    addQuestion({
      variables: {
        pageId,
        label: question.questionType,
        class: questionTypes.get(question.questionType),
        type: question.questionType,
        questionnaireId: params.questionnaireId,
        attributes: question.attributes,
      },
    });
  };

  const handleDuplicateQuestion = (question: any) => {
    setSelectedQuestion(null);
    setLoadQuestions(false);
    const pageId = uuid();
    addQuestion({
      variables: {
        pageId,
        label: question.label,
        class: question.class,
        type: question.type,
        questionnaireId: question.questionnaireId,
        body: question.body,
        questions: question.questions,
        attributes: question.attributes,
      },
    });
  };

  const onScroll = () => {
    clearTimeout(timeoutIntervalId);
    timeoutIntervalId = setTimeout(() => {
      setPaginationDetails({
        ...paginationDetails,
        limit: paginationDetails.limit + 10,
      });
    }, 500);
  };

  const getStageButtonText = (activeIndex: number) => {
    switch (activeIndex) {
      case 0:
        return 'Create questions';
      case 1:
        return 'Add translations';
      case 2:
        return 'Choose design';
      case 3:
        return 'Test questionnaire';
      case 4:
        return 'Share questionnaire';
      default:
        return '';
    }
  };

  const handleTabChange = (tabIndex: any) => {
    if (isQuestionFormEdited) {
      toggleUnsavedContentModal();
    } else {
      setSelectedTab(tabIndex);
    }
  };

  return (
    <div>
      <div className='breadcrumb-wrapper'>
        <div className='breadcrumb'>
          {!isProductModule ? (
            <NavLink
              className='breadcrumb__link'
              to={`/licenses/view/${props.license?._id}`}
            >
              {props.license?.name} /{' '}
            </NavLink>
          ) : (
            <span className='breadcrumb__title'>{`${props.product?.name}/ `}</span>
          )}
          <NavLink className='breadcrumb__link' to='/questionnaires'>
            Questionnaire /{' '}
          </NavLink>
          <span className='breadcrumb__sub'>
            {`${props?.questionnaire?.name || ''} ${
              props?.questionnaire?.version
                ? `(v${props?.questionnaire?.version})`
                : ''
            }`}{' '}
          </span>
          {props?.questionnaire?.status && (
            <span className='grid-col__badge'>{`${props?.questionnaire?.status}`}</span>
          )}
        </div>
      </div>
      <Tabs
        selectedIndex={selectedTab}
        onSelect={(index) => handleTabChange(index)}
      >
        <TabList>
          <AccessControl allowedPermissions={['update:survey']}>
            <Tab>
              <FontAwesomeIcon icon={faCog} /> Settings
            </Tab>
          </AccessControl>
          <Tab>
            <FontAwesomeIcon className='mr-2' icon={faQuestion} /> Questions
          </Tab>
          {isTranslationsVisible && (
            <Tab>
              <FontAwesomeIcon className='mr-2' icon={faPassport} />{' '}
              Translations
            </Tab>
          )}
          <Tab>
            <FontAwesomeIcon className='mr-2' icon={faLayerGroup} />
            Design
          </Tab>
          <Tab>
            <FontAwesomeIcon className='mr-2' icon={faFlagCheckered} />
            Test & Publish
          </Tab>
          {(props.questionnaire?.status === QuestionnaireStatus.PUBLISHED ||
            props.questionnaire?.surveyUUID) && (
            <Tab>
              <FontAwesomeIcon className='mr-2' icon={faShare} />
              Sharing
            </Tab>
          )}
          {(props.questionnaire?.status === QuestionnaireStatus.PUBLISHED ||
            props.questionnaire?.surveyUUID) && (
            <Feature name='surveyVersions'>
              <Tab>
                <FontAwesomeIcon className='mr-2' icon={faCode} />
                Versions
              </Tab>
            </Feature>
          )}
          {selectedTab < 5 && (
            <button
              className='questionnaire-stage-btn'
              onClick={(event) => {
                event.preventDefault();
                handleTabChange(selectedTab + 1);
              }}
            >
              {getStageButtonText(selectedTab)}{' '}
              <FontAwesomeIcon className='ml-2' icon={faArrowRight} />
            </button>
          )}
        </TabList>
        <AccessControl allowedPermissions={['update:survey']}>
          <TabPanel>
            <QuestionnaireSettings
              license={props.license}
              onSubmit={editQuestionnaire}
              onArchive={archiveSelectedQuestionnaire}
              toggleArchiveModal={toggleArchiveModal}
              openArchiveModal={openArchiveModal}
              data={props.questionnaire}
              archiveQuestionnaireError={archiveQuestionnaireError}
              archiveQuestionnaireLoading={archiveQuestionnaireLoading}
            />
          </TabPanel>
        </AccessControl>
        <TabPanel>
          <QuestionsList
            questions={props.questions?.results}
            handleAddQuestion={handleAddQuestion}
            handleUpdateQuestion={handleUpdateQuestion}
            handleQuestionSelect={setSelectedQuestion}
            handleDeleteQuestion={handleDeleteQuestion}
            onScroll={onScroll}
            selectedQuestion={selectedQuestion}
            questionnaire={props.questionnaire}
            onDragEnd={handleReOrderQuestions}
            onDuplicate={handleDuplicateQuestion}
            loadQuestions={loadQuestions}
            isLoading={addQuestionnaireLoading}
            onQuestionFormEdit={setIsQuestionFormEdited}
          />
        </TabPanel>
        {isTranslationsVisible && (
          <TabPanel>
            <QuestionnaireTranslations
              questions={props.questions?.results}
              handleQuestionSelect={setSelectedQuestion}
              onScroll={onScroll}
              selectedQuestion={selectedQuestion}
              questionnaire={props.questionnaire}
              loadQuestions={loadQuestions}
              isLoading={addQuestionnaireLoading}
              setActiveTab={setSelectedTab}
            />
          </TabPanel>
        )}
        <TabPanel>
          <QuestionnaireDesign
            data={{
              questionnaire: props.questionnaire,
              license: props.license,
              licenses: props.licenses,
              editSelectedQuestionnaire,
              isLoading: editQuestionnaireLoading,
              isProductModule,
            }}
            onQuestionFormEdit={setIsQuestionFormEdited}
          />
        </TabPanel>
        <TabPanel>
          <QuestionnaireShare
            data={{
              questionnaire: props.questionnaire,
              isProductModule,
            }}
          />
        </TabPanel>
        {(props.questionnaire?.status === QuestionnaireStatus.PUBLISHED ||
          props.questionnaire?.surveyUUID) && (
          <TabPanel>
            <SharingMethods
              questionnaire={props.questionnaire}
              license={props.license}
            />
          </TabPanel>
        )}
        {(props.questionnaire?.status === QuestionnaireStatus.PUBLISHED ||
          props.questionnaire?.surveyUUID) && (
          <TabPanel>
            <SurveyVersionsList />
          </TabPanel>
        )}
      </Tabs>
      <ContentSaveWarningModal
        modalIsOpen={openUnsavedContentModal}
        toggleModal={toggleUnsavedContentModal}
        modalTitle='Warning'
      />
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    questionnaire: state.questionnaire,
    questions: state.questions,
    product: state.product,
    license: state.license,
    licenses: state.user.licenses,
    searchData: state.searchData,
  };
};

export default connect(mapStateToProps, {
  setQuestionnaireDataToStore,
  setQuestionnaireToStore,
  setQuestionDataToStore,
})(QuestionsTabs);
