import { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { useRouteMatch } from 'react-router-dom';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import LicenseSettings from './LicenseSettings';
import LicenseUsers from './LicenseUsers';
import ToggleSwitch from '../Basic/ToggleSwitch';
import { useMutation, useQuery } from '@apollo/client';
import { licenseTypes } from '../../Utils/data';
import { Routes, ToastTypes } from '../../Utils/types';
import {
  ADD_LICENSE,
  UPDATE_LICENSE,
  GET_PAGINATED_LICENSES,
} from '../../Query/licenses.query';
import { connect } from 'react-redux';
import { displayToastMessage } from '../../States/Actions/licenseAction';
import { RegionFactory } from '../../Utils/regions';
import AccessControl from '../AccessControl';
import { dateWithMonthsDelay } from '../../Utils/util';
import Select from 'react-select';
import { GET_PAGINATED_PRODUCTS } from 'src/Query/products.query';
import { useToast } from '../Basic/Toastify';

interface IFormInput {
  name: string;
  users?: string[];
  licenseType: string;
  template: string;
  maxUsers: number;
  maxOpinions: number;
  maxExports: number;
  maxQuestions: number;
  maxOpenQuestions: number;
  region: string;
  expireAt: Date;
  isActive: boolean;
  maxActiveQuestionnaires: number;
  maxQuestionnaires: number;
  maxSubQuestionnaires: number;
  product: { value: string; label: string };
}

const AddEditLicense = (props: any) => {
  const { path } = useRouteMatch();
  const history = useHistory();
  const { showToast } = useToast();

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitted, isSubmitting },
    setValue,
    reset,
  } = useForm<IFormInput>();

  const paginationDetails = {
    limit: 10,
    page: 1,
    searchTerm: '',
  };

  const regions = new RegionFactory();

  const [isAdd, setIsAdd] = useState(false);
  const [editLicense, setEditLicense] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [productPagination, setProductPagination] = useState<any>({
    limit: 10,
    page: 1,
  });
  const [productList, setProductList] = useState([]);

  useEffect(() => {
    if (path === '/licenses/add') {
      setIsAdd(true);
      setValue('expireAt', dateWithMonthsDelay(12));
    }
  }, [path, setValue]);

  const [addLicense, { loading: addLicenseLoading }] = useMutation(
    ADD_LICENSE,
    {
      refetchQueries: [
        {
          query: GET_PAGINATED_LICENSES,
          variables: { ...paginationDetails },
        },
      ],
      errorPolicy: 'none',
      onCompleted: () => {
        props.displayToastMessage({
          success: true,
          message: 'License created successfully',
        });
        history.push(Routes.LICENSES_VIEW);
      },
      onError: (error: any) => {
        showToast(error.message, ToastTypes.ERROR);
        console.error(error);
      },
    }
  );

  const [updateLicense, { loading: updateLicenseLoading }] = useMutation(
    UPDATE_LICENSE,
    {
      refetchQueries: [
        {
          query: GET_PAGINATED_LICENSES,
          variables: { ...paginationDetails },
        },
      ],
      errorPolicy: 'none',
      onCompleted: async () => {
        props.displayToastMessage({
          success: true,
          message: 'License updated successfully',
        });
        history.push(Routes.LICENSES_VIEW);
      },
      onError: (error: any) => {
        showToast(error.message, ToastTypes.ERROR);
        console.error(error);
      },
    }
  );

  useEffect(() => {
    setIsLoading(addLicenseLoading || updateLicenseLoading);
  }, [addLicenseLoading, updateLicenseLoading]);

  if (props.license && !isSubmitted && !isSubmitting && !editLicense) {
    setEditLicense(props.license);
    reset({
      ...props.license,
      region: props.license.dataRegion,
      expireAt: new Date(Number(props.license.expireAt)),
    });
  }

  useQuery(GET_PAGINATED_PRODUCTS, {
    variables: {
      ...productPagination,
    },
    errorPolicy: 'all',
    onCompleted: (completedData) => {
      if (completedData) {
        setProductList(
          completedData.products?.results.map((product: any) => {
            return {
              value: product._id,
              label: product.name,
            };
          })
        );
      }
    },
  });

  const handleScroll = (event: any) => {
    setProductPagination({
      ...productPagination,
      limit: productPagination.limit + 10,
    });
  };

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    const license: any = {
      name: data.name,
      users: [],
      template: data.template,
      licenseType: data.licenseType,
      maxUsers: data.maxUsers,
      maxOpinions: data.maxOpinions,
      maxExports: data.maxExports,
      maxQuestions: data.maxQuestions,
      maxOpenQuestions: data.maxOpenQuestions,
      dataRegion: data.region,
      expireAt: data.expireAt.toISOString(),
      isActive: data.isActive,
      maxActiveQuestionnaires: data.maxActiveQuestionnaires,
      maxQuestionnaires: data.maxQuestionnaires,
      maxSubQuestionnaires: data.maxSubQuestionnaires,
    };
    if (data.product) {
      license.productId = data.product.value;
    }
    if (isAdd) {
      addLicense({ variables: license });
    } else {
      updateLicense({ variables: { ...license, licenseId: props.licenseId } });
    }
  };

  const renderOptionsList = (list: any[]) => {
    return list.map((item) => (
      <option value={item.code} key={item.code}>
        {item.label}
      </option>
    ));
  };

  return (
    <div className='add-license-screen'>
      <div className='page-header'>
        <div className='page-header__title'>
          <h2>Licenses: </h2>
        </div>
      </div>
      <div className='bg-wrapper'>
        <form onSubmit={handleSubmit(onSubmit)}>
          <section>
            <div className='form-wrapper'>
              <div className='row'>
                <div className='form-group col'>
                  <label>
                    Name <span className='mandatory'>*</span>
                  </label>
                  <input
                    type='text'
                    {...register('name', { required: true })}
                  />
                  {errors.name && (
                    <span className='error-msg'>Name is required.</span>
                  )}
                </div>
              </div>
              <div className='row'>
                <div className='form-group col'>
                  <label>Product</label>
                  <div style={{ marginTop: '1rem' }}>
                    <Controller
                      control={control}
                      name='product'
                      render={({ field: { onChange, value } }) => (
                        <Select
                          options={productList}
                          value={value}
                          key={'product'}
                          isDisabled={
                            editLicense !== null && editLicense !== undefined
                          }
                          onChange={onChange}
                          onMenuScrollToBottom={(event: any) => {
                            handleScroll(event);
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='form-group col'>
                  <label>
                    Region <span className='mandatory'>*</span>
                  </label>
                  <select
                    {...register('region', {
                      required: true,
                      disabled: !isAdd,
                    })}
                  >
                    {regions.getRegions().map((region: any) => {
                      return (
                        <option
                          value={region.regionCode}
                          key={region.regionCode}
                        >
                          {region.regionName}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className='form-group col'>
                  <label>
                    Template Set <span className='mandatory'>*</span>
                  </label>
                  <select
                    {...register('template', {
                      required: true,
                      disabled: !isAdd,
                    })}
                  >
                    <option value='English'>Basic Templates</option>
                    <option value='Dutch'>Basic Templates</option>
                  </select>
                </div>
              </div>
              <div className='row'>
                <div className='form-group col'>
                  <label>
                    Expire at <span className='mandatory'>*</span>
                  </label>
                  <Controller
                    control={control}
                    name='expireAt'
                    defaultValue={new Date()}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <DatePicker selected={value} onChange={onChange} />
                    )}
                  />
                </div>

                <div className='form-group col'>
                  <label>Is Active</label>
                  <Controller
                    control={control}
                    name='isActive'
                    defaultValue={false}
                    render={({ field: { onChange, value = false } }) => (
                      <ToggleSwitch handleSwitch={onChange} checked={value} />
                    )}
                  />
                </div>
              </div>
              <div className='row'>
                <div className='form-group col-6'>
                  <label>
                    License Type <span className='mandatory'>*</span>
                  </label>
                  <select
                    {...register('licenseType', {
                      required: true,
                      disabled: !isAdd,
                    })}
                  >
                    {renderOptionsList(licenseTypes)}
                  </select>
                </div>
              </div>
            </div>
          </section>
          <section>
            <LicenseUsers isAdd={!isAdd} users={props.license?.users} />
          </section>
          <section>
            <LicenseSettings register={register} />
          </section>
          <section className='action-footer'>
            <AccessControl
              allowedPermissions={['update:license', 'create:license']}
            >
              <button type='submit' disabled={isLoading}>
                {' '}
                Save{' '}
              </button>
            </AccessControl>
          </section>
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any, ownProps: any) => {
  if (ownProps.match?.params?.licenseId && state.licenses?.results?.length) {
    return {
      licenseId: ownProps.match.params.licenseId,
      license: state.licenses.results.find(
        (license: any) => ownProps.match.params.licenseId === license._id
      ),
    };
  } else {
    // TODO: refactor this
    return {
      licenseId: null,
      license: null,
    };
  }
};

export default connect(mapStateToProps, { displayToastMessage })(
  AddEditLicense
);
