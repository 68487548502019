import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Language } from 'src/Utils/types';
import HTMLEditor from '../../../Common/HTMLEditor';

const CustomPageQuestionTranslateComponent = ({ data }: any) => {
  const { questionnaire, question, languageCode } = data;

  const {
    control,
    formState: { errors },
    reset,
  } = useFormContext<any>();

  useEffect(() => {
    const questionData: any = {};
    questionnaire.supportedLanguages.forEach((language: Language) => {
      const languageData = question.questions.find(
        (questionData: any) => questionData.language?.code === language.code
      );
      Object.assign(questionData, {
        [language.code]: {
          html: languageData?.text?.html || null,
        },
      });
    });
    reset(questionData);
  }, [question, questionnaire, reset]);

  return (
    <div className='page-question-translater-wrappers'>
      <div className='form-group'>
        <div
          className={`html-editor  ${
            errors[languageCode]?.label ? 'is-invalid' : ''
          }`}
        >
          <Controller
            control={control}
            name={`${languageCode}.html`}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <HTMLEditor
                onChange={(value: string) => onChange(value)}
                value={value}
              />
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default CustomPageQuestionTranslateComponent;
