import React, { useCallback, useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useForm, Controller } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-datepicker';
import { customModal } from '../../Constants/ThemeO2T';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import languageData from '../../Utils/meta/languages.json';
import {
  getLanguageOptions,
  getOneLanguageOption,
  getOneLanguageObject,
  getOneLanguageObjectArray,
} from '../../Utils/languages';
import { connect } from 'react-redux';
import { dateWithMonthsDelay } from '../../Utils/util';
import styles from '../../Utils/meta/styles.json';

const animatedComponents = makeAnimated();
const initFormData = {
  name: '',
  defaultLanguage: '',
  supportedLanguages: [],
  activeFrom: '',
  activeTill: '',
};

const AddEditQuestionnaire: React.FC<any> = ({
  modalIsOpen,
  toggleModal,
  modalTitle,
  modalData,
  onSubmit,
  error,
  license,
  isLoading,
  isProductModule,
  productsList,
}) => {
  const languages = getLanguageOptions(languageData);
  const {
    register,
    formState: { errors },
    reset,
    getValues,
    setValue,
    control,
    setError,
    clearErrors,
  } = useForm<any>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {},
    resolver: undefined,
    context: undefined,
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: false,
    delayError: undefined,
  });

  const resetForm = useCallback(async () => {
    reset(initFormData);
  }, [reset]);

  const closeModal = () => {
    toggleModal();
    resetForm();
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    const licenseId = license?._id;
    const {
      name,
      defaultLanguage: defaultLanguageObj,
      supportedLanguages: supportedLanguagesObj,
      activeFrom,
      activeTill,
      product,
    } = getValues();

    const defaultLanguage =
      defaultLanguageObj && getOneLanguageObject(defaultLanguageObj.value);
    const supportedLanguages =
      supportedLanguagesObj && getOneLanguageObjectArray(supportedLanguagesObj);

    const isValid = isValidForm(
      name,
      supportedLanguagesObj,
      defaultLanguageObj,
      isProductModule,
      product
    );

    if (!isValid) return;

    let variables: any = {
      _id: modalData?._id,
      name,
      defaultLanguage: defaultLanguage || {},
      supportedLanguages: supportedLanguages || [],
      activeFrom,
      activeTill,
      styles: styles.customTemplate,
    };
    variables = isProductModule
      ? { ...variables, productId: product?.value, isProductItem: true }
      : { ...variables, licenseId };
    onSubmit({ variables });
  };

  const [defaultLanguageOptions, setDefaultLanguageOptions] = useState<any[]>(
    []
  );

  const isValidForm = (
    name: string,
    supportedLanguages: any[],
    defaultLanguage: any,
    isProductModule: boolean,
    product?: any
  ) => {
    clearErrors();
    if (!name) {
      setError('name', {
        type: 'custom',
        message: 'Name field is empty',
      });
    } else if (!supportedLanguages || supportedLanguages?.length === 0) {
      setError('supportedLanguages', {
        type: 'custom',
        message: 'Supported languages are empty',
      });
    } else if (!defaultLanguage || Object.keys(defaultLanguage).length === 0) {
      setError('defaultLanguage', {
        type: 'custom',
        message: 'Default language is empty',
      });
    } else if (isProductModule && !product) {
      setError('product', {
        type: 'custom',
        message: 'Product is empty',
      });
    } else {
      return (
        name &&
        supportedLanguages.length > 0 &&
        defaultLanguage &&
        Object.keys(defaultLanguage).length > 0
      );
    }
    return false;
  };

  const isIncluded = (array: any[], key: string, value: any): boolean => {
    return array.filter((item: any) => item[key] === value[key]).length !== 0;
  };

  const handleSupportedLanguagesOnChange = (supportedLanguageOptions: any) => {
    const { defaultLanguage } = getValues();

    setDefaultLanguageOptions(supportedLanguageOptions);

    if (!defaultLanguage) {
      setValue('defaultLanguage', supportedLanguageOptions?.[0]);
    } else if (
      !isIncluded(supportedLanguageOptions, 'value', defaultLanguage) &&
      supportedLanguageOptions.length
    ) {
      setValue('defaultLanguage', supportedLanguageOptions?.[0]);
    } else if (
      !isIncluded(supportedLanguageOptions, 'value', defaultLanguage) &&
      supportedLanguageOptions.length === 0
    ) {
      setValue('defaultLanguage', '');
    }
  };

  useEffect(() => {
    if (modalData) {
      setDefaultLanguageOptions(
        getLanguageOptions(modalData.supportedLanguages)
      );
      reset({
        ...modalData,
        activeFrom: new Date(Number(modalData.activeFrom)),
        activeTill: new Date(Number(modalData.activeTill)),
        defaultLanguage: getOneLanguageOption(modalData.defaultLanguage),
        supportedLanguages: getLanguageOptions(modalData.supportedLanguages),
      });
    } else {
      setValue('activeTill', dateWithMonthsDelay(1));
    }
  }, [modalData, reset, setValue]);

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        style={customModal}
        onRequestClose={closeModal}
        contentLabel='Add Questionnaire Modal'
      >
        <div className='o2t-modal'>
          <div className='o2t-modal__header'>
            <h2 className='o2t-modal__header-title'>{modalTitle}</h2>
          </div>

          <div className='o2t-modal__container'>
            <form onSubmit={handleSubmit}>
              <div className='form-group'>
                <label>
                  Questionnaire name <span className='mandatory'>*</span>
                </label>
                <input {...register('name', { required: true })} />
                {errors.name && (
                  <span className='error-msg'>
                    Questionnaire name is required.
                  </span>
                )}
              </div>

              {isProductModule && (
                <div className='form-group '>
                  <label>
                    Select a product <span className='mandatory'>*</span>
                  </label>

                  <Controller
                    control={control}
                    name='product'
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        options={productsList}
                        value={value}
                        onChange={(val) => {
                          return onChange(val);
                        }}
                        components={animatedComponents}
                      />
                    )}
                  />
                  {errors.product && (
                    <span className='error-msg'>Product is required.</span>
                  )}
                </div>
              )}

              <div className='form-group d-flex'>
                <div className='w-50 pr-4'>
                  <label>
                    Supported languages <span className='mandatory'>*</span>
                  </label>

                  <Controller
                    control={control}
                    name='supportedLanguages'
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        isMulti={true}
                        options={languages}
                        value={value}
                        onChange={(val) => {
                          handleSupportedLanguagesOnChange(val);
                          return onChange(val);
                        }}
                        components={animatedComponents}
                      />
                    )}
                  />
                  {errors.supportedLanguages && (
                    <span className='error-msg'>
                      Supported languages are required.
                    </span>
                  )}
                </div>
                <div className='w-50'>
                  <label>
                    Default language <span className='mandatory'>*</span>
                  </label>
                  <Controller
                    control={control}
                    name='defaultLanguage'
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        options={defaultLanguageOptions}
                        value={value}
                        onChange={onChange}
                      />
                    )}
                  />
                  {errors.defaultLanguage && (
                    <span className='error-msg'>
                      Default language is required.
                    </span>
                  )}
                </div>
              </div>
              <div className='form-group d-flex'>
                <div className='w-50'>
                  <label>Active till</label>
                  <Controller
                    control={control}
                    name='activeTill'
                    defaultValue={new Date()}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <DatePicker selected={value} onChange={onChange} />
                    )}
                  />
                </div>
              </div>

              <div className='alert-wrapper'>
                {error && <p>{error.message}</p>}
              </div>

              <div className='o2t-modal__footer'>
                <button
                  onClick={closeModal}
                  disabled={isLoading}
                  className='link-button mr-2'
                >
                  Cancel
                </button>
                <button type='submit' disabled={isLoading}>
                  {modalTitle === 'Add Questionnaire'
                    ? 'Add questionnaire'
                    : 'Save'}
                  <span>
                    <FontAwesomeIcon className='ml-3' icon={faChevronRight} />
                  </span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    license: state.license,
  };
};

export default connect(mapStateToProps)(AddEditQuestionnaire);
