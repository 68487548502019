import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Language, QuestionTypes } from 'src/Utils/types';
import { sanitizeQuillEditorOutput } from 'src/Utils/util';
import QuestionTextInput from '../../QuestionTextInput';

const MatrixQuestionTranslateComponent = ({ data }: any) => {
  const {
    questionnaire,
    question,
    languageCode,
    choices,
    setChoices,
    answers,
    setAnswers,
  } = data;

  const {
    control,
    formState: { errors },
    reset,
  } = useFormContext<any>();

  const questionData = question.questions.find(
    (questionData: any) => questionData.language?.code === languageCode
  );

  useEffect(() => {
    const questionData: any = {};
    const choicesData: any = {};
    const answersData: any = {};

    questionnaire.supportedLanguages.forEach((language: Language) => {
      const languageData = question?.questions?.find(
        (questionData: any) => questionData?.language?.code === language.code
      );

      Object.assign(questionData, {
        [language.code]: {
          id: languageData?.text?.id || null,
          main: languageData?.text?.main || null,
          sub: languageData?.text?.sub || null,
        },
      });
      choicesData[language.code] = languageData?.text?.choices || [];
      answersData[language.code] = languageData?.text?.answers || [];
    });

    setChoices(choicesData);
    setAnswers(answersData);
    reset(questionData);
  }, [question, questionnaire, reset, setChoices, setAnswers]);

  const handleChoiceValue = (
    value: string,
    choiceIndex: number,
    language: string
  ) => {
    const updatedChoices = choices?.[language]?.map(
      (choice: any, index: number) => {
        if (choiceIndex === index) {
          return {
            ...choice,
            content: value,
          };
        }
        return choice;
      }
    );

    if (updatedChoices) {
      const newChoices = { ...choices };
      newChoices[language] = updatedChoices;

      setChoices(newChoices);
    }
  };

  const handleAnswerValue = (
    value: string,
    itemIndex: number,
    language: any
  ) => {
    const updatedAnswers = answers?.[language]?.map(
      (answer: any, index: number) => {
        if (itemIndex === index) {
          return {
            ...answer,
            content: value,
          };
        }
        return answer;
      }
    );

    if (updatedAnswers) {
      const newAnswers = { ...answers };
      newAnswers[language] = updatedAnswers;

      setAnswers(newAnswers);
    }
  };

  return (
    <div className='page-question-translater-wrappers'>
      <div className='form-group'>
        <label>Question Text</label>
        <div
          className={`bottom-border-input italic-input translation-input-field ${
            errors[languageCode]?.label ? 'is-invalid' : ''
          }`}
        >
          <Controller
            control={control}
            name={`${languageCode}.main`}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <QuestionTextInput
                placeholder='Start typing question text...'
                onChange={(value: string) => {
                  const updatedValue = sanitizeQuillEditorOutput(value);
                  return onChange(updatedValue);
                }}
                value={value}
                disabled={data.isLoading}
              />
            )}
          />
        </div>
      </div>
      <div className='form-group'>
        <label>Description</label>
        <div
          className={`bottom-border-input italic-input translation-input-field ${
            errors[languageCode]?.label ? 'is-invalid' : ''
          }`}
        >
          <Controller
            control={control}
            name={`${languageCode}.sub`}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <QuestionTextInput
                placeholder='Add description to your question'
                onChange={(value: string) => {
                  const updatedValue = sanitizeQuillEditorOutput(value);
                  return onChange(updatedValue);
                }}
                value={value}
                disabled={data.isLoading}
              />
            )}
          />
        </div>
      </div>

      <div className='form-group'>
        <label>Questions</label>
        {questionData?.text?.choices?.map((choice: any, index: number) => {
          return (
            <div
              key={index}
              style={{ width: '50%' }}
              className={`bottom-border-input italic-input translation-input-field ${
                errors[languageCode]?.label ? 'is-invalid' : ''
              }`}
            >
              <QuestionTextInput
                style={{
                  display: 'inline-block',
                  width: '100%',
                }}
                onChange={(value: string) => {
                  const updatedValue = sanitizeQuillEditorOutput(value);
                  return handleChoiceValue(updatedValue, index, languageCode);
                }}
                value={choice.content}
                disabled={data.isLoading}
              />
            </div>
          );
        })}
      </div>

      {(question.type === QuestionTypes.CHOICE_PAGE ||
        (question.type === QuestionTypes.RATING_PAGE &&
          question.attributes?.showAsNumbers) === false) && (
        <div className='form-group'>
          <label>Choices</label>
          {questionData?.text?.answers?.map((answer: any, index: number) => {
            return (
              <div
                key={index}
                style={{ width: '50%' }}
                className={`bottom-border-input italic-input translation-input-field ${
                  errors[languageCode]?.label ? 'is-invalid' : ''
                }`}
              >
                <QuestionTextInput
                  style={{
                    display: 'inline-block',
                    width: '100%',
                  }}
                  onChange={(value: string) => {
                    const updatedValue = sanitizeQuillEditorOutput(value);
                    return handleAnswerValue(updatedValue, index, languageCode);
                  }}
                  value={answer.content}
                  disabled={data.isLoading}
                />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default MatrixQuestionTranslateComponent;
