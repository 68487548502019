import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { useLazyQuery, useMutation } from '@apollo/client';

import { IPage } from '../Interfaces';
import {
  displayToastMessage,
  setLinkDataSetDataToStore,
} from '../States/Actions/linkDataSetAction';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import Loading from '../Components/Basic/Loading';
import AccessControl from '../Components/AccessControl';
import Alert from '../Components/Basic/AlertComponent';
import LinkDataSetGrid from '../Components/LinkDataSetModule/LinkDataSetGrid';
import { DeleteLinkDataSetModal } from '../Components/LinkDataSetModule/DeleteLinkDataSetModal';
import AddEditLinkDataSetModal from 'src/Components/LinkDataSetModule/AddEditLinkDataSetModal';
import {
  ADD_LINK_DATA_SET,
  UPDATE_LINK_DATA_SET,
  DELETE_LINK_DATA_SET,
  GET_PAGINATED_LINK_DATA_SETS,
} from 'src/Query/linkDataSets.query';
import { useToast } from 'src/Components/Basic/Toastify';
import { ToastTypes } from 'src/Utils/types';

const LinkDataSets: React.FunctionComponent<IPage> = ({
  setLinkDataSetDataToStore,
  linkDataSetsToast,
  linkDataSets,
  total,
  next,
  previous,
  displayToastMessage,
  searchData,
}: any) => {
  const { showToast } = useToast();

  const [paginationDetails, setPaginationDetails] = useState<any>({
    limit: 10,
    page: 1,
    searchTerm: searchData.searchTerm,
  });
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [editLinkDataSetModal, setEditLinkDataSetModal] = useState(false);
  const [deleteLinkDataSet, setDeleteLinkDataSet] = useState(null);
  const [openAddEditLinkDataSetModal, setOpenAddEditLinkDataSetModal] =
    useState(false);
  const [selectedLinkDataSet, setSelectedLinkDataSet] = useState(null);

  const toggleDeleteModal = () => {
    setOpenDeleteModal(!openDeleteModal);
  };

  const toggleAddEditModal = (isEdit = false) => {
    setEditLinkDataSetModal(isEdit);
    setOpenAddEditLinkDataSetModal(!openAddEditLinkDataSetModal);
  };

  const handleSortByColumn = (sortBy: { name: string }, sortOrder: string) => {
    setPaginationDetails({
      limit: paginationDetails.limit,
      page: 1,
      sortBy: sortBy.name.toUpperCase().replaceAll(' ', '_'),
      sortOrder: sortOrder.toUpperCase(),
      searchTerm: searchData.searchTerm,
    });
  };

  const handlePageChange = (paginationDetails: any) => {
    setPaginationDetails({
      ...paginationDetails,
      searchTerm: searchData.search,
    });
  };

  const handleDeleteLinkDataSetSelect = (LinkDataSet: any) => {
    setDeleteLinkDataSet(LinkDataSet);
    toggleDeleteModal();
  };

  const handleLinkDataSetSelect = (row: any) => {
    setSelectedLinkDataSet(row);
    toggleAddEditModal(true);
  };

  const [fetchLinkDataSets, { loading, error }] = useLazyQuery(
    GET_PAGINATED_LINK_DATA_SETS,
    {
      errorPolicy: 'all',
      onCompleted: (completedData) => {
        if (completedData) {
          setLinkDataSetDataToStore(completedData.linkDataSets);
        }
      },
    }
  );

  useEffect(() => {
    fetchLinkDataSets({
      variables: { ...paginationDetails, searchTerm: searchData.searchTerm },
    });
  }, [paginationDetails, searchData, fetchLinkDataSets]);

  useEffect(() => {
    setPaginationDetails((prevPaginationDetails: any) => {
      return {
        ...prevPaginationDetails,
        searchTerm: searchData.searchTerm,
      };
    });
  }, [searchData]);

  const [
    addLinkDataSet,
    { error: addLinkDataSetError, loading: addLinkDataSetLoading },
  ] = useMutation(ADD_LINK_DATA_SET, {
    errorPolicy: 'none',
    onCompleted: () => {
      showToast('LinkDataSet added successfully');
      setPaginationDetails({
        limit: 10,
        page: 1,
        searchTerm: '',
      });
      toggleAddEditModal();
    },
    onError: (error: any) => {
      showToast(error.message, ToastTypes.ERROR);
      console.error(error);
    },
  });

  const [
    editLinkDataSet,
    { error: editLinkDataSetError, loading: editLinkDataSetLoading },
  ] = useMutation(UPDATE_LINK_DATA_SET, {
    errorPolicy: 'none',
    onCompleted: () => {
      showToast('LinkDataSet updated successfully');
      setPaginationDetails({
        limit: 10,
        page: 1,
        searchTerm: '',
      });
      toggleAddEditModal();
    },
    onError: (error: any) => {
      showToast(error.message, ToastTypes.ERROR);
      console.error(error);
    },
  });

  const [
    deleteSelectedLinkDataSet,
    { error: deleteLinkDataSetError, loading: deleteLinkDataSetLoading },
  ] = useMutation(DELETE_LINK_DATA_SET, {
    errorPolicy: 'none',
    onCompleted: () => {
      showToast('LinkDataSet deleted successfully');
      setPaginationDetails({
        limit: 10,
        page: 1,
        searchTerm: '',
      });
      toggleDeleteModal();
    },
    onError: (error: any) => {
      showToast(error.message, ToastTypes.ERROR);
      console.error(error);
    },
  });

  const handleSubmit = (params: any) => {
    if (editLinkDataSetModal) {
      editLinkDataSet(params);
    } else {
      addLinkDataSet(params);
    }
  };

  return (
    <div className='linkDataSets-screen'>
      <AccessControl
        allowedPermissions={['view:licenses_page']}
        renderNoAccess={() => (
          <Alert
            message={
              'Unauthorized. You do not have permission to view linkDataSets page'
            }
          />
        )}
      >
        <div className='page-header'>
          <div className='page-header__title'>
            <h2>Link Data Sets</h2>
          </div>
          <div className='page-header__actions'>
            <button
              onClick={(event: any) => {
                event.preventDefault();
                setSelectedLinkDataSet(null);
                toggleAddEditModal();
              }}
              className='btn-theme'
            >
              Add Link Data Sets
              <span>
                <FontAwesomeIcon className='ml-3' icon={faChevronRight} />
              </span>
            </button>
          </div>
        </div>
        {openDeleteModal && (
          <DeleteLinkDataSetModal
            modalIsOpen={openDeleteModal}
            toggleModal={toggleDeleteModal}
            modalTitle={'Delete Link Data Set'}
            modalData={deleteLinkDataSet}
            error={deleteLinkDataSetError}
            onDelete={deleteSelectedLinkDataSet}
            isLoading={deleteLinkDataSetLoading}
          />
        )}
        {openAddEditLinkDataSetModal && (
          <AddEditLinkDataSetModal
            modalIsOpen={openAddEditLinkDataSetModal}
            toggleModal={toggleAddEditModal}
            isEdit={editLinkDataSetModal}
            modalData={{
              selectedLinkDataSet,
            }}
            onSubmit={handleSubmit}
            error={addLinkDataSetError || editLinkDataSetError}
            isLoading={addLinkDataSetLoading || editLinkDataSetLoading}
          />
        )}
        <div className='grid-wrapper'>
          <LinkDataSetGrid
            data={{
              linkDataSets,
              total,
              loading,
              error,
              paginationDetails,
              next,
              previous,
              handlePageChange,
              handleSortByColumn,
              handleDeleteLinkDataSetSelect,
              setSelectedLinkDataSet,
              handleLinkDataSetSelect,
            }}
          />
        </div>
      </AccessControl>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user,
    linkDataSets: state.linkDataSets.results,
    hasNext: state.linkDataSets.hasNext,
    next: state.linkDataSets.next,
    hasPrevious: state.linkDataSets.hasPrevious,
    previous: state.linkDataSets.previous,
    total: state.linkDataSets.total,
    searchData: state.searchData,
  };
};

export default connect(mapStateToProps, {
  setLinkDataSetDataToStore,
  displayToastMessage,
})(
  withAuthenticationRequired(LinkDataSets, {
    onRedirecting: () => <Loading />,
  })
);
