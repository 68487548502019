import React, { Fragment, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Language, QuestionTypes, ToastTypes } from '../../../Utils/types';
import AccessControl from '../../AccessControl';
import TranslateComponentWrapper from './TranslateComponentWrapper';
import TranslateViewWrapper from './TranslateViewWrapper';

import { useMutation, useQuery } from '@apollo/client';
import { QUESTIONS, UPDATE_QUESTION } from 'src/Query/questions.query';
import { connect } from 'react-redux';
import { setQuestionDataToStore } from 'src/States/Actions/questionAction';
import { useToast } from 'src/Components/Basic/Toastify';

const QuestionTranslationWrapper = (props: any) => {
  const { showToast } = useToast();

  const { data, onOpenAddLanguageModel } = props;
  const { questionnaire, question, isLoading } = data;

  const [supportedLanguages, setSupportedLanguages] = useState<Language[]>([]);

  const handleUpdateQuestion = (question: any, questionData: any) => {
    updateQuestion({
      variables: {
        questionId: question._id,
        label: question.label,
        class: question.class,
        type: question.type,
        questions: questionData,
        attributes: question.attributes,
      },
    });
  };

  const methods = useForm<any>();

  const [choices, setChoices] = useState<any>({});
  const [answers, setAnswers] = useState<any>({});

  useEffect(() => {
    const supportedLanguages = questionnaire.supportedLanguages;
    setSupportedLanguages(
      supportedLanguages.filter(
        (language: Language) =>
          language.code !== questionnaire.defaultLanguage.code
      )
    );
  }, [questionnaire]);

  const onSubmit = (formData: any) => {
    const questionData = getQuestionsDataForForm(formData, question);

    const updatedQuestionData =
      question.type === QuestionTypes.CONDITIONAL
        ? [
            {
              ...question.questions?.[0],
              followupQuestion: {
                ...question.questions?.[0]?.followupQuestion,
                questions: questionData,
              },
            },
          ]
        : questionData;
    handleUpdateQuestion(question, updatedQuestionData);
  };

  const { refetch: refetchQuestions } = useQuery(QUESTIONS, {
    variables: {
      questionnaireId: questionnaire._id,
      limit: 20,
    },
    errorPolicy: 'all',
    onCompleted: (completedData: any) => {
      props.setQuestionDataToStore(completedData.questions);
    },
  });

  const [updateQuestion] = useMutation(UPDATE_QUESTION, {
    errorPolicy: 'none',
    onCompleted: () => {
      refetchQuestions();
      showToast('Question updated successfully');
    },
    onError: (error: any) => {
      showToast(error.message, ToastTypes.ERROR);
      console.error(error);
    },
  });

  const getQuestionsDataForForm = (formData: any, question: any) => {
    const defaultLanguage = questionnaire.defaultLanguage.code;

    const questionsData = Object.keys(formData).map((languageCode: string) => {
      const language = questionnaire.supportedLanguages.find(
        (language: Language) => languageCode === language.code
      );

      const questionType =
        question.type === QuestionTypes.CONDITIONAL
          ? question.questions?.[0].followupQuestion.type
          : question.type;
      const questionContent =
        question.type === QuestionTypes.CONDITIONAL
          ? question.questions?.[0].followupQuestion.questions
          : question.questions;

      const data: any = {
        language,
        text: formData[languageCode],
        conditions: questionContent.find(
          (questionData: any) => questionData.language.code === defaultLanguage
        )?.conditions,
      };

      if (questionType === QuestionTypes.RATING) {
        data.text.ratings = choices[languageCode];
      } else if (
        questionType === QuestionTypes.SINGLE_CHOICE ||
        questionType === QuestionTypes.MULTIPLE_CHOICE
      ) {
        data.text.choices = choices[languageCode];
      } else if (
        questionType === QuestionTypes.CHOICE_PAGE ||
        questionType === QuestionTypes.RATING_PAGE
      ) {
        data.text.choices = choices[languageCode];
        data.text.answers = answers[languageCode];
      }
      return data;
    });
    return questionsData;
  };

  return (
    <>
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          className='question-wrapper-form'
        >
          <div className='question-wrapper'>
            <div className='q-translate-content'>
              <div>
                <TranslateViewWrapper
                  data={{
                    questionnaire,
                    question,
                    isLoading,
                  }}
                />
              </div>
            </div>
            {supportedLanguages?.length !== 0 && <div className='divider' />}
            <div className='q-translate-language'>
              <div className='add-new'>
                <span>Add new languages</span>
                <button onClick={onOpenAddLanguageModel}>+</button>
              </div>
              {supportedLanguages?.length !== 0 && (
                <Fragment>
                  <div>
                    <div>
                      <TranslateComponentWrapper
                        data={{
                          questionnaire,
                          question,
                          isLoading,
                          choices,
                          setChoices,
                          answers,
                          setAnswers,
                        }}
                      />
                    </div>
                    <div className='btn-pre-wrapper'>
                      <AccessControl allowedPermissions={['update:question']}>
                        <button
                          type='submit'
                          className='save-btn'
                          disabled={isLoading}
                        >
                          Save
                        </button>
                      </AccessControl>
                    </div>
                  </div>
                </Fragment>
              )}
            </div>
          </div>
        </form>
      </FormProvider>
    </>
  );
};

export default connect(null, { setQuestionDataToStore })(
  QuestionTranslationWrapper
);
