import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import ColorPicker from './ColorPicker';

const ColorComponent = (props: any) => {
  const [openColorPicker, setOpenColorPicker] = useState(false);
  const [color, setColor] = useState(props.color.value);

  return (
    <div className='color-component'>
      <div className='color-component-lbl'>{props.color.label}</div>
      <div>
        <div
          className='color-component-wrapper'
          onClick={() => setOpenColorPicker(!openColorPicker)}
        >
          <div
            className='color-component-icon'
            style={{ backgroundColor: props.color.value }}
          />
          <div className='color-component-dropdown-caret'>
            <FontAwesomeIcon icon={faCaretDown} />
          </div>
        </div>
        {openColorPicker && (
          <ColorPicker
            onClose={() => setOpenColorPicker(false)}
            onColorChange={(color: any) => {
              setColor(color);
              props.onColorSelect(props.color.id, color);
            }}
            color={color}
          />
        )}
      </div>
    </div>
  );
};

export default ColorComponent;
