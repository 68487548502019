import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import ToggleSwitch from '../../Basic/ToggleSwitch';
import { OpenQuestionKeyboardType } from 'src/Utils/types';
import QuestionTextInput from '../QuestionTextInput';
import { sanitizeQuillEditorOutput } from 'src/Utils/util';

const OpenQuestion = (props: any) => {
  const { data } = props;
  const [keyboardOption, setKeyboardOption] = useState(
    data.question?.attributes?.inputKeyboard || ''
  );

  const { control, formState, register, reset, setValue } =
    useFormContext<any>();

  const { errors } = formState;

  const handleOption = (optionValue: string) => {
    setKeyboardOption(optionValue);
    if (optionValue !== OpenQuestionKeyboardType.TEXT) {
      setValue('sentimentAnalysis', false);
    }
  };

  const getQuestionsDataForForm = (questions: any, questionnaire: any) => {
    const formData: any = {};

    const question = questions.questions?.find(
      (question: any) =>
        question?.language?.code === questionnaire?.defaultLanguage?.code
    );
    formData[questionnaire?.defaultLanguage?.code] = {
      id: question?.text?.id || null,
      label: question?.text?.main || null,
      sub: question?.text?.sub || null,
      placeholder: question?.text?.placeholder || null,
    };

    return formData;
  };

  useEffect(() => {
    const questionsData = getQuestionsDataForForm(
      data.question,
      data.questionnaire
    );

    reset({
      ...questionsData,
      keyboard: data.question?.attributes?.inputKeyboard,
      maxCharacters: data.question?.attributes?.maxInput,
      optional: data.question?.attributes?.showSkip,
      sentimentAnalysis: data.question?.attributes?.sentimentAnalysis,
      hideBackButton: data.question?.attributes?.hideBackButton,
      regex: data.question?.attributes?.regexMask,
      minNumber: data.question?.attributes?.minNumber,
      maxNumber: data.question?.attributes?.maxNumber,
    });
  }, [data.question, data.questionnaire, reset]);

  return (
    <>
      {!data.settings && (
        <div>
          <div className='inner-tabs'>
            <div>
              <div className='col col-8 pt-4'>
                <div className='form-group'>
                  <input
                    className={`${
                      errors[data.questionnaire?.defaultLanguage.code]?.id
                        ? 'is-invalid'
                        : ''
                    } italic-input half-width-form-control `}
                    placeholder='Question label...'
                    type='text'
                    {...register(
                      `${data.questionnaire?.defaultLanguage.code}.id`,
                      {
                        required: true,
                      }
                    )}
                    disabled={data.isLoading}
                  />
                </div>

                <div className='form-group'>
                  <div
                    className={`bottom-border-input italic-input ${
                      errors[data.questionnaire?.defaultLanguage.code]?.label
                        ? 'is-invalid'
                        : ''
                    }`}
                  >
                    <Controller
                      control={control}
                      name={`${data.questionnaire?.defaultLanguage.code}.label`}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <QuestionTextInput
                          placeholder='Start typing question text...'
                          onChange={(value: string) => {
                            const updatedValue =
                              sanitizeQuillEditorOutput(value);
                            return onChange(updatedValue);
                          }}
                          value={value}
                          disabled={data.isLoading}
                        />
                      )}
                    />
                  </div>
                  <div className='bottom-border-input italic-input'>
                    <Controller
                      control={control}
                      name={`${data.questionnaire?.defaultLanguage.code}.sub`}
                      render={({ field: { onChange, value } }) => (
                        <QuestionTextInput
                          placeholder='Add description to your question'
                          onChange={(value: string) => {
                            const updatedValue =
                              sanitizeQuillEditorOutput(value);
                            return onChange(updatedValue);
                          }}
                          value={value}
                          disabled={data.isLoading}
                        />
                      )}
                    />
                  </div>
                  <input
                    className='no-border-input italic-input'
                    type='text'
                    placeholder='Add placeholder to your question'
                    {...register(
                      `${data.questionnaire?.defaultLanguage.code}.placeholder`
                    )}
                    disabled={data.isLoading}
                  />
                </div>
              </div>
            </div>
          </div>
          <div />
        </div>
      )}

      {data.settings && (
        <div className='q-settings__wrapper'>
          <div className='form-group d-flex'>
            <Controller
              control={control}
              name='optional'
              rules={{ required: false }}
              render={({ field: { onChange, value = false } }) => (
                <ToggleSwitch
                  handleSwitch={onChange}
                  checked={value}
                  width={36}
                />
              )}
            />
            <label className='ml-2'>Optional</label>
          </div>
          <div className='form-group d-flex'>
            <Controller
              control={control}
              name='hideBackButton'
              rules={{ required: false }}
              render={({ field: { onChange, value = false } }) => (
                <ToggleSwitch
                  handleSwitch={onChange}
                  checked={value}
                  width={36}
                />
              )}
            />

            <label className='ml-2'>Hide back button</label>
          </div>
          <div className='form-group d-flex'>
            <Controller
              control={control}
              name='sentimentAnalysis'
              rules={{ required: false }}
              render={({ field: { onChange, value = false } }) => (
                <ToggleSwitch
                  handleSwitch={onChange}
                  checked={value}
                  width={36}
                  disabled={keyboardOption !== OpenQuestionKeyboardType.TEXT}
                />
              )}
            />
            <label className='ml-2'>Sentiment analysis</label>
          </div>
          <div className='form-group'>
            <label className='mb-1'>Max characters</label>
            <input {...register(`maxCharacters`)} type='number' />
          </div>
          <div className='form-group'>
            <label className='mb-1'>Type of keyboard</label>
            <Controller
              control={control}
              name='keyboard'
              rules={{ required: false }}
              render={({
                field: { onChange, value = OpenQuestionKeyboardType.TEXT },
              }) => (
                <select
                  onChange={(event) => {
                    onChange(event);
                    handleOption(event.target.value);
                  }}
                  value={value}
                >
                  <option value={OpenQuestionKeyboardType.NUMBER}>
                    Number
                  </option>
                  <option value={OpenQuestionKeyboardType.EMAIL}>Email</option>
                  <option value={OpenQuestionKeyboardType.DATE}>Date</option>
                  <option value={OpenQuestionKeyboardType.TEXT}>Text</option>
                </select>
              )}
            />
          </div>
          {keyboardOption === OpenQuestionKeyboardType.NUMBER && (
            <div className='d-flex'>
              <div className='form-group'>
                <label className='mb-1'>Min value</label>
                <input type='number' {...register(`minNumber`)} />
              </div>
              <div className='form-group ml-3'>
                <label className='mb-1'>Max value</label>
                <input type='number' {...register(`maxNumber`)} />
              </div>
            </div>
          )}
          {keyboardOption === OpenQuestionKeyboardType.CUSTOM && (
            <>
              <div className='form-group'>
                <label className='mb-1'>Regex mask</label>
                <input
                  type='text'
                  {...register(`regex`)}
                  placeholder='Type here'
                />
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default OpenQuestion;
