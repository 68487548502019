import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronRight,
  faCopy,
  faDownload,
} from '@fortawesome/free-solid-svg-icons';
import { Controller, useForm } from 'react-hook-form';

import makeAnimated from 'react-select/animated';
import { customModal } from '../../../Constants/ThemeO2T';

import { sharingMethods } from 'src/Utils/util';
import { useQuery } from '@apollo/client';
import Select from 'react-select';
import { GET_PAGINATED_LINK_DATA_SETS } from 'src/Query/linkDataSets.query';
import { LinkTypes } from 'src/Utils/types';
import QRCode from 'qrcode';
import { useToast } from 'src/Components/Basic/Toastify';

const animatedComponents = makeAnimated();

const AddEditSharingMethodModal: React.FC<any> = ({
  modalIsOpen,
  toggleModal,
  isEdit,
  modalData,
  onSubmit,
  error,
  isLoading,
}) => {
  const { showToast } = useToast();

  const [linkDataSetPaginationOptions, setLinkDataSetPaginationOptions] =
    useState({
      limit: 10,
    });
  const [linkDataSets, setLinkDataSets] = useState([]);
  const [showSurveyLink, setShowSurveyLink] = useState(false);
  const [qr, setQr] = useState('');

  const modalTitle = isEdit ? 'Edit Sharing method' : 'Add Sharing method';

  const {
    register,
    reset,
    getValues,
    control,
    setValue,
    formState: { errors },
    watch,
  } = useForm<any>({ mode: 'onSubmit' });

  const sharingMethodWatch = watch('method');

  const closeModal = () => {
    toggleModal();
  };

  const clearForm = () => {
    reset({
      name: '',
    });
  };

  useEffect(() => {
    const showSurveyLinkTypes = [LinkTypes.OPEN_LINK, LinkTypes.PORTAL_LINK];
    setShowSurveyLink(
      showSurveyLinkTypes.indexOf(modalData?.selectedSharingMethod?.type) !== -1
    );
  }, [modalData?.selectedSharingMethod?.type]);

  useEffect(() => {
    if (
      isEdit &&
      modalData?.selectedSharingMethod?.type === LinkTypes.OPEN_LINK
    ) {
      QRCode.toDataURL(
        modalData?.selectedSharingMethod?.link,
        { width: 250 },
        (error, url) => {
          if (error) return console.error(error);
          setQr(url);
        }
      );
    }
  }, [modalData, isEdit]);

  useEffect(() => {
    if (isEdit) {
      reset({
        method: sharingMethods.find(
          (sharingMethod) =>
            sharingMethod.value === modalData?.selectedSharingMethod?.type
        ),
        name: modalData?.selectedSharingMethod?.name,
        maxResponses:
          modalData?.selectedSharingMethod?.maxResponses === -1
            ? null
            : modalData?.selectedSharingMethod?.maxResponses,
      });

      if (modalData?.selectedSharingMethod?.type === LinkTypes.UNIQUE_LINK) {
        const selectedLinkDataSet: any = linkDataSets.find(
          (linkDataSet: any) =>
            linkDataSet.value ===
            modalData?.selectedSharingMethod?.linkDataSetId
        );
        setValue('linkDataSet', {
          value: modalData?.selectedSharingMethod?.linkDataSetId,
          label: selectedLinkDataSet?.label,
        });
      }
    }
  }, [isEdit, modalData?.selectedSharingMethod, linkDataSets, reset, setValue]);

  const { data } = useQuery(GET_PAGINATED_LINK_DATA_SETS, {
    variables: { ...linkDataSetPaginationOptions },
    errorPolicy: 'all',
  });

  useEffect(() => {
    if (data) {
      const linkDataSets = data.linkDataSets?.results?.map(
        (linkDataSet: any) => {
          return {
            label: linkDataSet.name,
            value: linkDataSet._id.toString(),
          };
        }
      );
      setLinkDataSets(linkDataSets);
    }
  }, [data, setValue]);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const { name, method, maxResponses, linkDataSet } = getValues();

    const variables: any = {
      type: method.value,
      name,
      questionnaireId: modalData?.questionnaire?._id,
      maxResponses: maxResponses?.trim() === '' ? -1 : Number(maxResponses),
    };

    if (method.value === LinkTypes.UNIQUE_LINK) {
      variables.linkDataSetId = linkDataSet.value;
    }

    if (isEdit) {
      variables.sharingMethodId = modalData?.selectedSharingMethod?._id;
    }

    onSubmit({ variables }, isEdit);
    clearForm();
  };

  const handleScroll = (event: any) => {
    event.preventDefault();
    setLinkDataSetPaginationOptions({
      ...linkDataSetPaginationOptions,
      limit: linkDataSetPaginationOptions.limit + 10,
    });
  };

  const handleCopyButtonClick = (link: string) => {
    navigator.clipboard.writeText(link);
    showToast('Survey link copied to clipboard');
  };

  const getLinkData = (sharingMethod: any) => {
    const linkData = {
      label: '',
      value: '',
    };
    if (sharingMethod) {
      linkData.label =
        sharingMethod.type === LinkTypes.OPEN_LINK.toString()
          ? 'Survey link'
          : 'Link Id';
      linkData.value =
        sharingMethod.type === LinkTypes.OPEN_LINK
          ? modalData?.selectedSharingMethod?.link
          : modalData?.selectedSharingMethod?.surveyUUID;
    }
    return linkData;
  };

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel='Add Sharing Method Modal'
        style={customModal}
      >
        <div className='o2t-modal'>
          <div className='o2t-modal__header'>
            <h2 className='o2t-modal__header-title'>{modalTitle}</h2>
          </div>

          <div className='o2t-modal__container'>
            <form onSubmit={handleSubmit}>
              <div className='form-group'>
                <label>
                  Sharing method <span className='mandatory'>*</span>
                </label>
                <Controller
                  control={control}
                  name='method'
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      isDisabled={isEdit}
                      options={sharingMethods}
                      value={value}
                      onChange={onChange}
                      components={animatedComponents}
                    />
                  )}
                />

                {errors.method && (
                  <span className='error-msg'>Sharing method is required.</span>
                )}
              </div>
              {isEdit && showSurveyLink && (
                <div className='form-group'>
                  <label>
                    {getLinkData(modalData?.selectedSharingMethod).label}
                  </label>
                  <div className='share-link'>
                    <input
                      type='text'
                      disabled={true}
                      value={
                        getLinkData(modalData?.selectedSharingMethod).value
                      }
                    />
                    <button
                      onClick={(event) => {
                        event.preventDefault();
                        handleCopyButtonClick(
                          modalData?.selectedSharingMethod?.link
                        );
                      }}
                    >
                      <FontAwesomeIcon icon={faCopy} />
                    </button>
                  </div>
                </div>
              )}
              <div className='form-group'>
                <label>Name</label>
                <input className='' {...register('name')} />
              </div>
              <div className='form-group number-field'>
                <label>Max responses</label>
                <input className='' {...register('maxResponses')} />
              </div>

              {sharingMethodWatch?.value === LinkTypes.UNIQUE_LINK && (
                <div className='form-group'>
                  <label>
                    Link dataset <span className='mandatory'>*</span>
                  </label>
                  <Controller
                    control={control}
                    name='linkDataSet'
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        options={linkDataSets}
                        value={value}
                        onMenuScrollToBottom={handleScroll}
                        onChange={onChange}
                        components={animatedComponents}
                        maxMenuHeight={150}
                      />
                    )}
                  />

                  {errors.linkDataSet && (
                    <span className='error-msg'>
                      Sharing method is required.
                    </span>
                  )}
                </div>
              )}
              <div className='alert-wrapper'>
                {error && <p>{error.message}</p>}
              </div>
              <div>
                {qr && (
                  <div className='qr-wrapper'>
                    <img alt='QR Code' src={qr} />
                    <a
                      download={`${modalData?.selectedSharingMethod?.name} QR.png`}
                      href={qr}
                      className='download-link'
                    >
                      <FontAwesomeIcon icon={faDownload} />
                    </a>
                  </div>
                )}
              </div>
              <div className='o2t-modal__footer' style={{ paddingTop: '1rem' }}>
                <button
                  onClick={closeModal}
                  className='link-button mr-2'
                  disabled={isLoading}
                >
                  Cancel
                </button>
                <button
                  type='submit'
                  data-testid='user-save-btn'
                  disabled={isLoading}
                >
                  {isEdit ? 'Save' : 'Add sharing method'}
                  <span>
                    <FontAwesomeIcon className='ml-3' icon={faChevronRight} />
                  </span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddEditSharingMethodModal;
