import { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Select from 'react-select';
import { QuestionTypes } from 'src/Utils/types';
import { isValidParentQuestion } from 'src/Utils/util';

const displayQuestionOptions = [
  { value: 'show', label: 'Show the question' },
  { value: 'hide', label: "Don't show the question" },
];

const answerConditionOptions = [
  { value: 'is', label: 'Is' },
  { value: 'isnt', label: 'Is not' },
];
const answerMultipleConditionOptions = [
  { value: 'is-any', label: 'Includes Any' },
  { value: 'is-not-any', label: 'Excludes Any' },
  { value: 'is-all', label: 'Includes All' },
  { value: 'is-not-all', label: 'Excludes All' },
];
const connectorOptions = [
  { value: 'and', label: 'AND' },
  { value: 'or', label: 'OR' },
];

const getAnswersList = (selectedQuestion: any) => {
  let answersList = [];
  if (
    selectedQuestion?.type === QuestionTypes.SINGLE_CHOICE ||
    selectedQuestion?.type === QuestionTypes.MULTIPLE_CHOICE
  ) {
    answersList = selectedQuestion?.questions?.[0]?.text?.choices?.map(
      ({ content, _id }: { content: string; _id: string }) => {
        return {
          value: _id,
          label: content,
        };
      }
    );
  } else if (
    selectedQuestion?.type === QuestionTypes.RATING ||
    selectedQuestion?.type === QuestionTypes.RATING_PAGE
  ) {
    const { numberOfChoices, showAsNumbers, isNPS } =
      selectedQuestion.attributes || {};
    answersList = showAsNumbers
      ? [...Array(Number(numberOfChoices)).keys()].map((choice: number) => {
          return {
            value: isNPS ? choice : choice + 1,
            label: isNPS ? choice : choice + 1,
          };
        })
      : selectedQuestion.questions?.[0]?.text?.ratings?.map(
          ({ label, rating }: { label: string; rating: string }) => {
            return {
              value: rating,
              label,
            };
          }
        );
  } else if (
    // selectedQuestion?.type === QuestionTypes.RATING_PAGE ||
    selectedQuestion?.type === QuestionTypes.CHOICE_PAGE
  ) {
    answersList = selectedQuestion.questions?.[0]?.text?.answers?.map(
      ({ content, _id }: { content: string; _id: string }) => {
        return {
          value: _id,
          label: content,
        };
      }
    );
  }
  return answersList;
};

const QuestionConditionalComponent = (props: any) => {
  const { data, onDeleteCondition } = props;
  const { index, questions, condition, conditions, question, item } = data;

  const [questionsList, setQuestionsList] = useState<any>([]);

  const [answers, setAnswers] = useState<any[]>([]);
  const [matrixQustions, setMatrixQuestions] = useState<any[]>([]);

  const [selectedQuestion, setSelectedQuestion] = useState<any>(null);

  const { control, reset, getValues, watch } = useFormContext<any>();

  const parentQuestionWatch = watch(`condition.[${index}].question`);

  let condition_var = 'condition';
  if ('item' in data) {
    condition_var = `subCondition.${item._id}`;
  }
  useEffect(() => {
    setQuestionsList([
      { label: 'Select a question', value: 'none' },
      ...(questions
        ?.filter(
          (ques: any) =>
            isValidParentQuestion(question.type) && ques.order < question.order
        )
        ?.map((question: any) => {
          return {
            label: question.label,
            value: question._id,
          };
        }) || []),
    ]);
  }, [questions, question]);

  useEffect(() => {
    const values = getValues();

    const selectedQuestion = questions?.find(
      ({ _id }: { _id: string }) => question?._id === _id
    );
    const answersList = getAnswersList(selectedQuestion);
    setAnswers(answersList);
    if (conditions !== undefined)
      reset({
        ...values,
        condition: [
          ...conditions?.map((condition: any) => {
            return {
              display: condition?.display,
              question: {
                value:
                  condition?.question?.value || condition?.parentQuestion?._id,
                label:
                  condition?.question?.label ||
                  condition?.parentQuestion?.label,
              },
              condition: condition?.condition,
              answers: condition?.answers,
              connector: condition?.connector,
              matrixQuestion: condition?.matrixQuestion,
            };
          }),
        ],
      });
  }, [question, questions, conditions, reset, getValues]);

  useEffect(() => {
    const selectedQuestion = questions?.find(
      ({ _id }: { _id: string }) => parentQuestionWatch?.value === _id
    );
    const answersList = getAnswersList(selectedQuestion);
    setSelectedQuestion(selectedQuestion);
    setAnswers(answersList);

    if (
      selectedQuestion?.type === QuestionTypes.CHOICE_PAGE ||
      selectedQuestion?.type === QuestionTypes.RATING_PAGE
    ) {
      setMatrixQuestions(
        selectedQuestion.questions?.[0]?.text?.choices?.map(
          ({ content, _id }: { content: string; _id: string }) => {
            return {
              value: _id,
              label: content,
            };
          }
        ) || []
      );
    }
  }, [parentQuestionWatch, condition, questions]);

  return (
    <div className='question-condition-container'>
      <div className='question-condition-header'>
        <div className='condition-lbl'>{`Condition ${index + 1}`}</div>i{' '}
        <div
          className='delete-btn'
          onClick={() => {
            if (item) onDeleteCondition(index, item._id);
            else onDeleteCondition(index);
          }}
        >
          Delete
        </div>
      </div>
      {index > 0 && (
        <div
          className='condition-input-group__full-width'
          style={{ textAlign: 'center' }}
        >
          <Controller
            control={control}
            name={`${condition_var}.[${index}].connector`}
            render={({ field: { onChange, value } }) => (
              <Select
                options={connectorOptions}
                value={value}
                onChange={(option: any) => {
                  return onChange(option);
                }}
              />
            )}
          />
        </div>
      )}
      <div className='condition-input-group__full-width'>
        <Controller
          control={control}
          name={`${condition_var}.[${index}].display`}
          defaultValue={displayQuestionOptions[0]}
          render={({ field: { onChange, value } }) => (
            <Select
              options={displayQuestionOptions}
              value={value}
              onChange={(option: any) => {
                return onChange(option);
              }}
            />
          )}
        />
      </div>
      <div className='condition-input-group'>
        <div className='condition-input-left'>
          <Controller
            control={control}
            name={`${condition_var}.[${index}].if`}
            defaultValue={{ value: 'if', label: 'If' }}
            render={({ field: { onChange, value } }) => (
              <Select
                options={[{ value: 'if', label: 'If' }]}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </div>
        <div className='condition-input-right'>
          <Controller
            control={control}
            name={`${condition_var}.[${index}].question`}
            defaultValue={questionsList?.[0]}
            render={({ field: { onChange, value } }) => (
              <Select
                options={questionsList}
                value={value}
                onChange={(option: any) => {
                  return onChange(option);
                }}
                getOptionLabel={(question) =>
                  question?.label?.replace(/<[^>]+>/g, '')
                }
              />
            )}
          />
        </div>
      </div>
      {(selectedQuestion?.type === QuestionTypes.CHOICE_PAGE ||
        selectedQuestion?.type === QuestionTypes.RATING_PAGE) && (
        <div className='condition-input-group'>
          <div className='condition-input-left' />
          <div className='condition-input-right'>
            <Controller
              control={control}
              name={`${condition_var}.[${index}].matrixQuestion`}
              render={({ field: { onChange, value } }) => (
                <Select
                  options={matrixQustions}
                  value={value}
                  getOptionLabel={(answer) =>
                    `${answer?.label}`?.replace(/<[^>]+>/g, '')
                  }
                  onChange={(option: any) => {
                    return onChange(option);
                  }}
                />
              )}
            />
          </div>
        </div>
      )}
      <div className='condition-input-group'>
        {selectedQuestion?.type === QuestionTypes.MULTIPLE_CHOICE ? (
          <div className='condition-input-left'>
            <Controller
              control={control}
              name={`${condition_var}.[${index}].condition`}
              defaultValue={answerMultipleConditionOptions?.[0]}
              render={({ field: { onChange, value } }) => (
                <Select
                  options={answerMultipleConditionOptions}
                  value={value}
                  onChange={(option: any) => {
                    return onChange(option);
                  }}
                />
              )}
            />
          </div>
        ) : (
          <div className='condition-input-left'>
            <Controller
              control={control}
              name={`${condition_var}.[${index}].condition`}
              defaultValue={answerConditionOptions?.[0]}
              render={({ field: { onChange, value } }) => (
                <Select
                  options={answerConditionOptions}
                  value={value}
                  onChange={(option: any) => {
                    return onChange(option);
                  }}
                />
              )}
            />
          </div>
        )}
        <div className='condition-input-right'>
          <Controller
            control={control}
            name={`${condition_var}.[${index}].answers`}
            render={({ field: { onChange, value } }) => (
              <Select
                isMulti={true}
                closeMenuOnSelect={false}
                options={answers}
                value={value}
                getOptionLabel={(answer) =>
                  `${answer?.label}`?.replace(/<[^>]+>/g, '')
                }
                onChange={(option: any) => {
                  return onChange(option);
                }}
              />
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default QuestionConditionalComponent;
