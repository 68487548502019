import React, { useState, useEffect, useMemo } from 'react';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Controller, useForm } from 'react-hook-form';
import ContentLoader from 'react-content-loader';

import { customModal } from '../../Constants/ThemeO2T';
import AccessControl from '../AccessControl';
import { customAlphabet } from 'nanoid';
import { alphaNumbericAlphabet, getInitialColorStyles } from 'src/Utils/util';
import ColorComponent from '../QuestionnaireModule/ColorComponent';
import FileUpload from '../Common/FileUpload';
import { ImageType, Regions } from 'src/Utils/types';
import defaultStyles from '../../Utils/meta/styles.json';
import { GET_SIGNED_URL } from 'src/Query/questionnaires.query';
import { useLazyQuery, useQuery } from '@apollo/client';
import axios from 'axios';
import { RegionFactory } from 'src/Utils/regions';
import Select from 'react-select';
import { GET_LICENSES_BY_IDS } from 'src/Query/licenses.query';
import makeAnimated from 'react-select/animated';
import CodeEditor from '../Common/CodeEditor';

const animatedComponents = makeAnimated();

const nanoid = customAlphabet(alphaNumbericAlphabet, 15);

const ImageSkeleton = () => {
  return (
    <ContentLoader viewBox='0 0 300 100'>
      <rect x='0' y='20' rx='5' ry='5' width='150' height='100' />
    </ContentLoader>
  );
};

const AddEditStyleTemplateModal: React.FC<any> = ({
  modalIsOpen,
  toggleModal,
  isEdit,
  modalData,
  onSubmit,
  error,
  isLoading,
  loadLicenseOptions,
  handleLicenseDropdownScroll,
  isProductModule,
  productsList,
  handleProductDropdownScroll,
}) => {
  const regions = useMemo(() => new RegionFactory(), []);

  const [styles, setStyles] = useState<any>(
    getInitialColorStyles(modalData.styles || defaultStyles.customTemplate)
  );

  const [option, setOption] = useState<string>(
    modalData.styles?.styleTemplateType || 'Normal'
  );
  const [isImageLoading, setIsImageLoading] = useState(true);
  const [companyLogo, setCompanyLogo] = useState<File | undefined>();
  const [backgroundImage, setBackgroundImage] = useState<File | undefined>();
  const [region, setRegion] = useState<any>(
    regions.getRegion(Regions.EU_WEST_1)
  );
  const [logoDeleted, setLogoDeleted] = useState(false);
  const [backgroundImgDeleted, setBackgroundImageDeleted] = useState(false);
  const [skipFetchLicenses, setSkipFetchLicenses] = useState(true);

  const modalTitle = isEdit ? 'Edit Style Template' : 'Add Style Template';

  const {
    register,
    reset,
    getValues,
    control,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm<any>();

  const closeModal = () => {
    toggleModal();
  };

  const clearForm = () => {
    reset({
      name: '',
      license: '',
      logo: '',
      backgroundImage: '',
      customCSS: '',
    });
    setCompanyLogo(undefined);
    setBackgroundImage(undefined);
  };

  const { data } = useQuery(GET_LICENSES_BY_IDS, {
    variables: { ids: [modalData?.selectedStlyeTemplate?.licenseId] },
    errorPolicy: 'all',
    skip: skipFetchLicenses,
  });

  useEffect(() => {
    if (data) {
      const selectedLicenses = data.licensesByIds.map((license: any) => {
        return {
          label: license.name,
          value: license._id.toString(),
        };
      });
      setValue('license', selectedLicenses[0]);
    }
  }, [data, setValue]);

  useEffect(() => {
    const licenseId = modalData?.selectedStlyeTemplate?.licenseId;
    if (licenseId) {
      const license = modalData.licenses.find(
        (license: any) => licenseId === license._id
      );
      setRegion(regions.getRegion(license?.dataRegion));
    }
  }, [modalData, regions]);

  const uploadImage = (url: string, file: File) => {
    return axios.put(url, file, {
      headers: {
        'Content-Type': file?.type,
        'Cache-Control': 'max-age=31536000',
      },
    });
  };

  const fetchSignURL = (key: string, licenseId: string) => {
    return getSignedURL({
      variables: {
        licenseId,
        key,
      },
    });
  };

  const handleImageUpload = async (
    licenseId: string,
    key: string,
    file?: File
  ) => {
    const {
      data: {
        signedURL: { url },
      },
    } = await fetchSignURL(key, licenseId);
    if (url && file) {
      return uploadImage(url, file);
    } else {
      setIsImageLoading(false);
      return;
    }
  };

  const getParams = ({
    name,
    license,
    productId,
    colors,
    customCSS,
    backgroundImageKey,
    logoKey,
  }: any) => {
    const variables: any = {
      name,
      styleTemplateType: option,
      isProductItem: isProductModule,
      ...colors,
      customCSS,
    };

    if (isProductModule) {
      variables.productId = productId;
    } else {
      variables.licenseId = license;
    }

    if (isEdit) {
      variables.styleTemplateId = modalData.selectedStlyeTemplate._id;
    }

    if (backgroundImageKey) {
      variables.backgroundImage = backgroundImageKey;
    }

    if (logoKey) {
      variables.logo = logoKey;
    }

    variables.logo =
      logoDeleted && (modalData?.styles?.logo === logoKey || !logoKey)
        ? null
        : variables.logo;
    variables.backgroundImage =
      backgroundImgDeleted &&
      (modalData?.styles?.backgroundImage === backgroundImageKey ||
        !backgroundImageKey)
        ? null
        : variables.backgroundImage;
    return variables;
  };

  const getFolderNameForImage = (
    option: string,
    isProductItem: boolean,
    license?: any,
    product?: any
  ) => {
    if (isProductItem) {
      return `product/${product?.value}`;
    }
    return option === 'Normal' ? `normal/${license?.value}` : 'system';
  };

  const handleSubmitAction = () => {
    const { name, customCSS, license, product } = getValues();

    const colors: any = {};
    styles.forEach((color: any) => {
      colors[color.key] = color.value;
    });

    let logoKey = null;
    if (companyLogo?.name) {
      logoKey = `style-templates/${getFolderNameForImage(
        option,
        isProductModule,
        license,
        product
      )}/${nanoid()}.${companyLogo?.name.split('.').pop()}`;
    }
    let backgroundImageKey = null;
    if (backgroundImage?.name) {
      backgroundImageKey = `style-templates/${getFolderNameForImage(
        option,
        isProductModule,
        license,
        product
      )}/${nanoid()}.${companyLogo?.name.split('.').pop()}`;
    }
    const imageUploadPromises: any[] = [];
    if (logoKey && companyLogo) {
      const logoUploadPromise = handleImageUpload(
        license?.value,
        logoKey,
        companyLogo
      );
      imageUploadPromises.push(logoUploadPromise);
    }

    if (backgroundImageKey && backgroundImage) {
      const backgroundUploadPromise = handleImageUpload(
        license?.value,
        backgroundImageKey,
        backgroundImage
      );
      imageUploadPromises.push(backgroundUploadPromise);
    }
    Promise.all(imageUploadPromises);

    const variables: any = getParams({
      name,
      productId: product?.value,
      license: license?.value,
      colors,
      customCSS,
      backgroundImageKey,
      logoKey,
    });

    onSubmit({ variables });
    clearForm();
  };

  const [getSignedURL] = useLazyQuery(GET_SIGNED_URL, {
    errorPolicy: 'ignore',
  });

  useEffect(() => {
    setOption(modalData.selectedStlyeTemplate?.styleTemplateType || 'Normal');
    setSkipFetchLicenses(
      modalData.selectedStlyeTemplate?.licenseId === null ||
        modalData.selectedStlyeTemplate?.licenseId === undefined
    );

    reset({
      name: modalData?.styles?.name,
      logo: modalData?.styles?.logo,
      backgroundImage: modalData?.styles?.backgroundImage,
      customCSS: modalData?.styles?.customCSS,
    });

    setValue(
      'product',
      productsList.find(
        (product: any) =>
          product.value === modalData?.selectedStlyeTemplate?.productId
      )
    );
    setIsImageLoading(false);
  }, [
    modalData.styles,
    modalData.selectedStlyeTemplate,
    isEdit,
    reset,
    productsList,
    setValue,
  ]);

  const onColorSelect = (id: number, color: string) => {
    const selectedColorIndex = styles.findIndex(
      (color: any) => color.id === id
    );

    if (selectedColorIndex !== null && selectedColorIndex !== undefined) {
      const updatedStyles = [...styles];
      updatedStyles[selectedColorIndex].value = color;
      setStyles(updatedStyles);
    }
  };

  const onImageDelete = (type: string) => {
    if (type === ImageType.LOGO) {
      setLogoDeleted(true);
    } else if (type === ImageType.BACKGROUND) {
      setBackgroundImageDeleted(true);
    }
  };

  let timerId: any;
  const handleLicenseOptionsSearch = (searchTerm: string) => {
    if (timerId) {
      clearInterval(timerId);
    }
    timerId = setTimeout(() => {
      loadLicenseOptions({ limit: 10, searchTerm });
    }, 500);
  };

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel='Add Style Template Modal'
        style={{
          content: {
            ...customModal.content,
            width: '50%',
            height: 'fit-content',
            maxHeight: '90%',
            overflow: 'hidden',
          },
        }}
      >
        <div className='o2t-modal'>
          <div className='o2t-modal__header'>
            <h2 className='o2t-modal__header-title'>{modalTitle}</h2>
          </div>

          <div className='o2t-modal__container'>
            <form onSubmit={handleSubmit(handleSubmitAction)}>
              <div className='form-group'>
                <div className='style-template-design'>
                  <div>
                    <div className='design-content-action-info'>
                      {!isProductModule && (
                        <AccessControl allowedRoles={['admin']}>
                          <div className='style-template-input-field'>
                            <div>Style template type</div>
                            <div className='style-template-type'>
                              <div className='style-template-type-option'>
                                <input
                                  type='radio'
                                  name='link-type'
                                  value='System'
                                  checked={option === 'System'}
                                  onChange={(event) =>
                                    setOption(event.target.value)
                                  }
                                  disabled={isEdit}
                                  className='link-type-form-field'
                                />
                                <span className='link-type-txt'>System</span>
                              </div>
                              <div className='style-template-type-option'>
                                <input
                                  type='radio'
                                  name='link-type'
                                  value='Normal'
                                  checked={option === 'Normal'}
                                  disabled={isEdit}
                                  className='link-type-form-field'
                                  onChange={(event) =>
                                    setOption(event.target.value)
                                  }
                                />
                                <span className='link-type-txt'>Normal</span>
                              </div>
                            </div>
                          </div>
                        </AccessControl>
                      )}

                      {option === 'Normal' && !isProductModule && (
                        <div className='style-template-input-field'>
                          <div>License</div>
                          <div className='style-template-type__select'>
                            <Controller
                              control={control}
                              name='license'
                              rules={{
                                required: true,
                              }}
                              render={({ field }) => (
                                <Select
                                  {...field}
                                  placeholder={
                                    isLoading ? 'Loading...' : 'Search...'
                                  }
                                  key={'license'}
                                  isDisabled={isEdit}
                                  options={modalData.licenses.map(
                                    (license: any) => {
                                      return {
                                        value: license?._id,
                                        label: license?.name,
                                      };
                                    }
                                  )}
                                  value={field?.value}
                                  onInputChange={handleLicenseOptionsSearch}
                                  onMenuScrollToBottom={
                                    handleLicenseDropdownScroll
                                  }
                                  onChange={field?.onChange}
                                />
                              )}
                            />
                          </div>{' '}
                          {errors.license && (
                            <span className='error-msg'>
                              License is required.
                            </span>
                          )}
                        </div>
                      )}

                      {isProductModule && (
                        <div className='style-template-input-field'>
                          <div>Select a product</div>
                          <div style={{ marginTop: '1rem' }}>
                            <Controller
                              control={control}
                              name='product'
                              rules={{ required: true }}
                              render={({ field: { onChange, value } }) => (
                                <Select
                                  options={productsList}
                                  value={value}
                                  key={'product'}
                                  isDisabled={isEdit}
                                  onChange={onChange}
                                  onMenuScrollToBottom={(event: any) => {
                                    handleProductDropdownScroll(event);
                                  }}
                                  components={animatedComponents}
                                />
                              )}
                            />
                          </div>
                          {errors.product && (
                            <span className='error-msg'>
                              Product is required.
                            </span>
                          )}
                        </div>
                      )}

                      <div className='style-template-input-field'>
                        <div>Style template name</div>
                        <div style={{ marginTop: '1rem' }}>
                          <input
                            className='form-field-full-length'
                            type='text'
                            {...register('name', {
                              required: true,
                            })}
                          />
                        </div>
                        {errors.name && (
                          <span className='error-msg'>Name is required.</span>
                        )}
                      </div>
                      <div className='design-content-action-info--title'>
                        <div className='style-template-content-info-header'>
                          Colors
                        </div>
                      </div>
                      <div className='style-template-clr-options-container'>
                        {styles.map((color: any) => {
                          return (
                            <ColorComponent
                              key={color.id}
                              color={color}
                              onColorSelect={onColorSelect}
                            />
                          );
                        })}
                        <div className='style-template-clr-options-lbl'>
                          <div>Company logo</div>
                          {isImageLoading ? (
                            <ImageSkeleton />
                          ) : (
                            <FileUpload
                              url={region?.imageUrl}
                              imageKey={modalData.styles?.logo}
                              onChange={setCompanyLogo}
                              altText={'Logo'}
                              type={ImageType.LOGO}
                              onImageDelete={onImageDelete}
                            />
                          )}
                        </div>
                        <div className='style-template-clr-options-lbl'>
                          <div>Background image</div>
                          {isImageLoading ? (
                            <ImageSkeleton />
                          ) : (
                            <FileUpload
                              url={region?.imageUrl}
                              imageKey={modalData.styles?.backgroundImage}
                              onChange={setBackgroundImage}
                              altText={'Background'}
                              type={ImageType.BACKGROUND}
                              onImageDelete={onImageDelete}
                            />
                          )}
                        </div>
                        <div className='style-template-textarea'>
                          <div>Custom CSS</div>
                          <div style={{ marginTop: '1rem' }}>
                            <Controller
                              control={control}
                              name={`customCSS`}
                              rules={{ required: true }}
                              render={({ field: { onChange, value } }) => (
                                <CodeEditor
                                  onChange={onChange}
                                  value={value}
                                  isCss={true}
                                />
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='alert-wrapper'>
                  {error && <p>{error.message}</p>}
                </div>

                <div
                  className='o2t-modal__footer'
                  style={{ paddingTop: '1rem' }}
                >
                  <button
                    onClick={closeModal}
                    className='link-button mr-2'
                    disabled={isLoading}
                  >
                    Cancel
                  </button>
                  <button
                    type='submit'
                    data-testid='user-save-btn'
                    disabled={isLoading}
                  >
                    {isEdit ? 'Save' : 'Add style template'}
                    <span>
                      <FontAwesomeIcon className='ml-3' icon={faChevronRight} />
                    </span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddEditStyleTemplateModal;
