import React from 'react';
import { Routes } from 'src/Utils/types';
import o2tLogo from '../assets/signin-person.png';
import history from '../Constants/history';
import LoginButton from './Basic/LoginButton';
import Header from './Layout/Header';

const LoginScreen = () => {
  const queryParameters = new URLSearchParams(window.location.search);
  const deviceId = queryParameters.get('deviceId');

  if (deviceId) {
    sessionStorage.setItem('deviceId', deviceId);
    history.push(Routes.HOME_VIEW);
  }

  return (
    <>
      <Header />

      <div className='login-container'>
        <div className='login-txt'>
          <div className='login-img-container '>
            <div className='login-img-circle'>
              <div className='login-img'>
                <img src={o2tLogo} alt='Brand Logo' width='350' />
              </div>
            </div>
          </div>
          <div className='login-action-container'>
            <div className='btn-wrapper'>
              <LoginButton />
              <button
                onClick={(event) => {
                  event.preventDefault();
                  window.open(
                    `${process.env.REACT_APP_SURVEY_URL}/link`,
                    '_self'
                  );
                }}
              >
                GENERATE QR
              </button>
            </div>
          </div>
        </div>
        <div className='bg-bubble-container'>
          <div id='full-circle-one' className='circle__fill' />
          <div id='full-circle-two' className='circle__fill' />
          <div id='outer-circle-one' className='circle__border' />
          <div id='outer-circle-two' className='circle__border' />
          <div id='outer-circle-three' className='circle__border' />
        </div>
      </div>
    </>
  );
};

export default LoginScreen;
